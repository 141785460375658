import { ActionTypes } from "../types/action.types";

const initialState = {
    error: false,
    loading: false,
    selectedDetraction: null,
    alertMsg: "",
};
export const detractionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SELECTING_DETRACTION:
            return { ...state, selectedDetraction: action.payload };

        default:
            return state;
    }
};
