import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { hideablesColumns } from "../../utils/arrays";
import { useLocalStorage } from "../../hooks/useLocalStorage";

//styles and material
import { Box, Button, Grid, TextField } from "@material-ui/core";
import useStyles from "../../styles/Styles";
import Loader from "../loader/Loader";

//redux
import { useSelector, useDispatch } from "react-redux";
import { tableScrollAction } from "../../redux/actions/sale.action";
import { listCompaniesAction } from "../../redux/actions/company.action";
import { listSellersAction } from "../../redux/actions/seller.action";
import { listSalesAction } from "../../redux/actions/sale.action";
import { listBanksAction } from "../../redux/actions/bank.action";

//componentes
import TableSales from "./TableSales";
import MenuList from "./MenuListColumns";
import MenuListFilter from "./MenuListFilter";

//utils
import { statePayments } from "../../utils/arrays";

const Compnay = () => {
    const classes = useStyles();

    //DISPATCH PARA TRAER EL DESPACHADOR QUE SETEA EL SCROLL
    const dispatch = useDispatch();

    const listCompaniesDispatch = () => dispatch(listCompaniesAction());
    const listSellersDispatch = () => dispatch(listSellersAction());
    const listSalesDispatch = () => dispatch(listSalesAction());
    const listBanksDispatch = () => dispatch(listBanksAction());
    const tableScrollDispatch = (data) => dispatch(tableScrollAction(data));

    const scroll = useRef(null);

    const [infoColumns, setinfoColumns] = useLocalStorage("hideablesColumns", hideablesColumns);

    const sales = useSelector((state) => state.sales.sales); //TRAE ARRAY VENTAS
    const salesOpened = useSelector((state) => state.sales.salesOpened); // TRAE ARRAY ESTADO COLLAPSE
    const load = useSelector((state) => state.sales.loading); //EL ESTADO DE LOADING DE LAS VENTAS
    const heightScroll = useSelector((state) => state.sales.scrollTable); //LA ALTURA DEL SCROLL
    const sellers = useSelector((state) => state.sellers.sellers);
    const companies = useSelector((state) => state.companies.companies);
    const banks = useSelector((state) => state.banks.banks);

    let years_ini = [
        ...new Set([
            ...sales.map((e) => new Date(e.sale_date_ini).getFullYear()), //agrego el arrego
            new Date().getFullYear(), //en caso de comenzar un nuevo año
        ]),
    ];

    let years_end = [
        ...new Set([
            ...sales.map((e) => new Date(e.sale_date_end).getFullYear()), //agrego el arrego
            new Date().getFullYear(), //en caso de comenzar un nuevo año +1
        ]),
    ];

    const [filters, setFilters] = useState({
        opt_date_ini: false,
        opt_date_end: false,
        opt_seller: false,
        opt_state: false,
        input_search: "",
        select_date_ini: years_ini[0],
        select_date_end: years_end[0],
        select_seller: sellers[0],
        select_state: statePayments,
    });

    // EL FILTRO DE COLUMNAS MOSTRADAS
    const hideColumns = (value) => {
        return infoColumns.find((e) => e.name === value).open;
    };

    // EL FILTRO PARA MOSTRAR VENTAS
    const filterSales = (rows) => {
        return rows.filter(
            (row) =>
                (row.sale_company.company_name
                    .toLowerCase()
                    .indexOf(filters.input_search.toLowerCase()) > -1 || // filtro por nombre empresa
                    row.sale_company.company_ruc
                        .toLowerCase()
                        .indexOf(filters.input_search.toLowerCase()) > -1) && //filtro por ruc
                row.sale_seller?.seller_name.indexOf(
                    filters.opt_seller ? filters.select_seller?.seller_name : ""
                ) > -1 &&
                row.sale_date_ini.indexOf(filters.opt_date_ini ? filters.select_date_ini : "") >
                    -1 &&
                row.sale_date_end.indexOf(filters.opt_date_end ? filters.select_date_end : "") >
                    -1 &&
                (filters.opt_state ? filters.select_state.includes(row.sale_state) : row)
        );
    };

    useEffect(
        () => {
            scroll?.current?.scrollTo(0, heightScroll);
            !companies.length && listCompaniesDispatch();
            !sellers.length && listSellersDispatch();
            !sales.length && listSalesDispatch();
            !banks.length && listBanksDispatch();
        },
        // eslint-disable-next-line
        []
    );

    /*//////////////////////////////////*/
    /*                                  */
    /*              JSX                 */
    /*                                  */
    // EN CASO DE ESTAR CARGANDO LA LISTA
    if (load) {
        return <Loader />;
    }

    return (
        <Box>
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={5} sm={3} lg={2} xl={2}>
                        <Button
                            fullWidth
                            size="small"
                            className={classes.fullHeight}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={"/sales/add"}
                        >
                            Agregar Cobranza
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} lg={3} xl={2}>
                        <TextField
                            fullWidth
                            size="small"
                            className={classes.button}
                            name="input_search"
                            label="Buscador"
                            variant="outlined"
                            onChange={(e) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                }))
                            }
                        />
                    </Grid>

                    <Grid item xs={"auto"} sm={"auto"} lg={"auto"} xl={"auto"}>
                        <Grid container className={classes.fullHeight} alignContent="center">
                            <MenuList infoColumns={infoColumns} setinfoColumns={setinfoColumns} />
                        </Grid>
                    </Grid>

                    <Grid item xs={"auto"} sm={"auto"} lg={"auto"} xl={"auto"}>
                        <Grid container className={classes.fullHeight} alignContent="center">
                            <MenuListFilter
                                filters={filters}
                                setFilters={setFilters}
                                sellers={sellers}
                                years_ini={years_ini}
                                years_end={years_end}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <TableSales
                    sales={filterSales(sales)}
                    hideColumns={hideColumns}
                    salesOpened={salesOpened}
                    tableScrollDispatch={tableScrollDispatch}
                    scroll={scroll}
                />
            </Box>
        </Box>
    );
};

export default Compnay;
