import { combineReducers } from "redux";

import { authReducer } from "./auth.reducer";
import { companyReducer } from "./company.reducer";
import { sellerReducer } from "./seller.reducer";
import { saleReducer } from "./sale.reducer";
import { paymentReducer } from "./payment.reducer";
import { detractionReducer } from "./detraction.reducer";
// import { estructureReducer } from "./estructure.reducer";
import { bankReducer } from "./bank.reducer";
import { sunatReducer } from "./sunat.reducer";
import { serviceReducer } from "./service.reducer";

import { salebrReducer } from "./salebr.reducer";

export default combineReducers({
    auth: authReducer,
    companies: companyReducer,
    sellers: sellerReducer,
    sales: saleReducer,
    payments: paymentReducer,
    detractions: detractionReducer,
    // estructures: estructureReducer,
    banks: bankReducer,
    sunat: sunatReducer,
    services: serviceReducer,

    salesbr: salebrReducer,
});
