import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

//styles and materials
import { Table, TableContainer, TableBody, Paper, TablePagination, Box } from "@material-ui/core";
import useStyles from "../../styles/Styles";

//redux
import { useDispatch } from "react-redux";
import { selectSaleAction } from "../../redux/actions/sale.action";

//componentes
import TableSalesHeader from "./TableSalesHeader";
import TableSalesBodyRow from "./TableSalesBodyRow";

//functions
import { obtainOpen } from "../../utils/functions";

const TableSales = ({ sales, hideColumns, salesOpened, tableScrollDispatch, scroll }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectSaleDispatch = (data) => dispatch(selectSaleAction(data));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectAndOpen = (data) => {
        selectSaleDispatch(data);
        history.push("/sales/edit");
    };

    const findSaleOpened = (data) => {
        return salesOpened.find((e) => e._id === data._id).open;
    };

    const spaceContent = clsx(
        classes.heightTableSale,
        obtainOpen() ? classes.widthOpenTableSale : classes.widthTableSale
    );

    return (
        <Box>
            <TableContainer
                ref={scroll}
                className={spaceContent}
                component={Paper}
                onScroll={(e) => tableScrollDispatch(e.target.scrollTop)}
            >
                <Table size="small">
                    <TableSalesHeader hideColumns={hideColumns} />

                    <TableBody>
                        {(rowsPerPage > 0
                            ? sales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : sales
                        ).map((sale) => (
                            <TableSalesBodyRow
                                key={sale._id}
                                sale={sale}
                                selectAndOpen={selectAndOpen}
                                hideColumns={hideColumns}
                                findSaleOpened={findSaleOpened}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                style={{ display: "flex" }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={sales.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Hojas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                backIconButtonText="Anterior"
                nextIconButtonText="Siguiente"
            />
        </Box>
    );
};

export default TableSales;
