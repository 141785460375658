import { ActionTypes } from "../types/action.types";
import { listBanksAPI } from "../../axios/bankAPI";

//LISTADO DE EMPRESAS
const listingBanks = () => ({
    type: ActionTypes.LISTING_BANKS,
    payload: true,
});

const listBanks = (banks) => ({
    type: ActionTypes.LIST_BANKS,
    payload: banks,
});

const listBanksError = (error) => ({
    type: ActionTypes.LIST_BANKS_ERROR,
    payload: error,
});

export const listBanksAction = () => {
    return async (dispatch) => {
        dispatch(listingBanks());

        try {
            const res = await listBanksAPI();
            dispatch(listBanks(res.data));
        } catch (error) {
            dispatch(listBanksError(error.response.data.error));
        }
    };
};

//EDICIÓN DE EMPRESA
const selectingBank = (bank) => ({
    type: ActionTypes.SELECTING_BANK,
    payload: bank,
});

export const selectBankAction = (bank) => {
    return async (dispatch) => {
        dispatch(selectingBank(bank));
    };
};
