import { ActionTypes } from "../types/action.types";
import { addSellerAPI, listSellersAPI, editSellerAPI } from "../../axios/sellerAPI";

//LISTADO DE EMPRESAS
const listingSellers = () => ({
    type: ActionTypes.LISTING_SELLERS,
    payload: true,
});

const listSellers = (sellers) => ({
    type: ActionTypes.LIST_SELLERS,
    payload: sellers,
});

const listSellersError = (error) => ({
    type: ActionTypes.LIST_SELLERS_ERROR,
    payload: error,
});

export const listSellersAction = () => {
    return async (dispatch) => {
        dispatch(listingSellers());

        try {
            const res = await listSellersAPI();
            dispatch(listSellers(res.data));
        } catch (error) {
            dispatch(listSellersError(error.response.data.error));
        }
    };
};

//AGREGADO DE EMPRESAS
const addSeller = () => ({
    type: ActionTypes.ADD_SELLER,
    payload: true,
});

const addSellerDone = (seller) => ({
    type: ActionTypes.ADD_SELLER_DONE,
    payload: seller,
});

const addSellerError = (error) => ({
    type: ActionTypes.ADD_SELLER_ERROR,
    payload: error,
});

export const addSellerAction = (seller) => {
    return async (dispatch) => {
        dispatch(addSeller());

        try {
            const res = await addSellerAPI(seller);

            //si todo sale bien actualizar el state
            dispatch(addSellerDone(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(addSellerError(error.response.data.error));
        }
    };
};

//EDICIÓN DE EMPRESA
const selectingSeller = (seller) => ({
    type: ActionTypes.SELECTING_SELLER,
    payload: seller,
});

export const selectSellerAction = (seller) => {
    return async (dispatch) => {
        dispatch(selectingSeller(seller));
    };
};

//EDITAR EMPRESA
const editingSeller = () => ({
    type: ActionTypes.EDITING_SELLER,
    payload: true,
});

const editSeller = (params) => ({
    type: ActionTypes.EDIT_SELLER_DONE,
    payload: params,
});

const editSellerError = (error) => ({
    type: ActionTypes.EDIT_SELLER_ERROR,
    payload: error,
});

export const editSellerAction = (seller) => {
    return async (dispatch) => {
        dispatch(editingSeller());

        try {
            const res = await editSellerAPI(seller);

            //si todo sale bien actualizar el state
            dispatch(editSeller(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editSellerError(error.response.data.error));
        }
    };
};
//
