import { makeStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";

// const drawerWidth = 217;
const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },

    container: { height: "100vh", width: "100vw" },
    paper: {
        // marginTop: theme.spacing(8),
        height: "85%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    form: {
        width: "100%", // Fix IE 11 issue.
    },

    formRegister: {
        backgroundColor: theme.palette.background.paper,
        width: "85% ",
        margin: "auto",
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: {
            width: "90% ",
            margin: "auto ",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin: 0,
        },
    },

    formSection: {
        width: "95%",
        margin: "auto",
    },

    // formRegister: {
    //     width: "80% !important",
    //     margin: "auto !important",
    //     [theme.breakpoints.down("sm")]: {
    //         width: "90% !important",
    //         margin: "auto !important",
    //     },
    //     [theme.breakpoints.down("xs")]: {
    //         width: "100%",
    //         margin: 0,
    //     },
    // },

    submit: {
        margin: theme.spacing(3, 0, 2),
        display: "flex",
        justifyContent: "center",
    },

    fullHeight: {
        height: "100%",
    },

    addSearch: {
        // display: "flex",
        marginBottom: "20px",
    },

    rootTable: {
        "& > *": {
            borderBottom: "unset",
        },
    },

    headTable: {
        backgroundColor: colors.grey[600],
    },

    colorCell: {
        "& td:nth-child(n+2)": {
            backgroundColor: colors.grey[600],
            color: colors.grey[100] + " !important",
            fontWeight: "500 !important",
        },
    },

    centered: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    iconAdd: {
        color: theme.palette.success.main,
    },

    heightTableSale: {
        maxHeight: `calc(100vh - 250px)`,
    },

    widthTableSale: {
        maxWidth: `calc(100vw - 48px)`,
    },

    widthOpenTableSale: {
        maxWidth: `calc(100vw - 265px)`,
        [theme.breakpoints.down("sm")]: {
            maxWidth: `calc(87vw)`,
        },
    },

    stickyHeadTable: {
        position: "sticky",
        top: 0,
        zIndex: 2,
    },

    noWrap: {
        whiteSpace: "nowrap",
    },

    paddingCell: {
        "& >td": {
            paddingLeft: "5px",
            paddingRight: "9px",
        },
        "& >th": {
            paddingLeft: "5px",
            paddingRight: "9px",
        },
    },

    menu: {
        // '&[data-focus="true"]'
        '&[aria-selected="true"]': {
            color: colors.amber[800],
            fontWeight: 700,
            fontSize: 13.5,
        },
    },

    success: {
        "& label.Mui-focused": {
            color: "green",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "green",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "green",
            },
            "&:hover fieldset": {
                borderColor: "green",
            },
            "&.Mui-focused fieldset": {
                borderColor: "green",
            },
        },
        backgroundColor: "#F0F8FF",
        "& input": {
            color: "green",
        },
        "& p": {
            color: "green",
        },
        "& label": {
            color: "green",
        },
    },

    chip: {
        display: "flex",
        justifyContent: "center",
        // flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },

    snack: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },

    //
    //
    //
    listCollapse: {
        color: colors.amber[800],
        "& .MuiListItemIcon-root, .MuiListItemText-root, .MuiSvgIcon-root": {
            color: colors.amber[800],
        },
    },
}));

export default useStyles;
