import React from "react";

//styles and materials
import { TableHead, TableCell, TableRow } from "@material-ui/core";
import useStyles from "../../../styles/Styles";

import { Tooltip, IconButton } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";

const TablePaymentsHeader = ({ selectAndOpen, sale }) => {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={6}>
                    <div className={classes.centered}>
                        <div>HISTORIAL DE PAGOS</div>
                        <div style={{ position: "absolute", right: 10 }}>
                            <Tooltip title="Agregar Pago">
                                <IconButton onClick={() => selectAndOpen(sale, null, "add")}>
                                    <AddCircleIcon className={classes.iconAdd} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={classes.headTable}>
                <TableCell align="center" padding="none">
                    FECHA DE PAGO
                </TableCell>
                <TableCell align="center" padding="none">
                    N° PAGO
                </TableCell>
                <TableCell align="center" padding="none">
                    BANCO
                </TableCell>
                <TableCell align="center" padding="none">
                    DETALLE
                </TableCell>
                <TableCell align="center" padding="none">
                    MONTO
                </TableCell>
                <TableCell align="center" padding="none">
                    ACCIONES
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TablePaymentsHeader;
