import React, { useEffect, useState } from "react";

//styles and material
import { Box, Button, Grid, TextField } from "@material-ui/core";
import useStyles from "../../styles/Styles";

//redux
import { useSelector, useDispatch } from "react-redux";
import { listCompaniesAction } from "../../redux/actions/company.action";

//componentes
import CompanyTable from "./TableCompany";
import AddEditCompany from "./AddEditCompany";
import Loader from "../loader/Loader";

const Compnay = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [typeSubmit, setTypeSubmit] = useState("add");
    const [searcher, setSearcher] = useState("");

    const listCompaniesDispatch = () => dispatch(listCompaniesAction());

    const load = useSelector((state) => state.companies.loading);
    const companies = useSelector((state) => state.companies.companies);

    const filterSearch = (rows) => {
        return rows.filter(
            (row) =>
                row.company_name.toLowerCase().indexOf(searcher.toLowerCase()) > -1 ||
                row.company_ruc.toLowerCase().indexOf(searcher.toLowerCase()) > -1
        );
    };

    const openCloseAndType = (type) => {
        setTypeSubmit(type);
        setOpenAddEdit(!openAddEdit);
    };

    useEffect(
        () => {
            !companies.length && listCompaniesDispatch();
        },
        // eslint-disable-next-line
        []
    );

    if (load) {
        return <Loader />;
    }

    if (openAddEdit) {
        return <AddEditCompany typeSubmit={typeSubmit} openCloseAndType={openCloseAndType} />;
    }

    return (
        <Box>
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} lg={2} xl={2}>
                        <Button
                            fullWidth
                            size="small"
                            className={classes.fullHeight}
                            variant="contained"
                            color="primary"
                            onClick={() => openCloseAndType("add")}
                        >
                            Agregar Empresa
                        </Button>
                    </Grid>

                    <Grid item xs={6} sm={4} lg={3} xl={2}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            label="Buscador"
                            variant="outlined"
                            onChange={(e) => setSearcher(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Box>

            <CompanyTable companies={filterSearch(companies)} openCloseAndType={openCloseAndType} />
        </Box>
    );
};

export default Compnay;
