import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

//styles and materials
import useStyles from "../../styles/Styles";
import {
    Grid,
    Button,
    Box,
    Typography,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../loader/Loader";

//redux
import { addServiceAction } from "../../redux/actions/service.action";
import { useDispatch, useSelector } from "react-redux";

//components
import CustomField from "../customComponents/CustomField";
import { date } from "../../utils/functions";

const AddEditService = () => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const addServiceDispatch = (data) => dispatch(addServiceAction(data));

    const companies = useSelector((state) => state.companies.companies);

    const loadCompanies = useSelector((state) => state.companies.loading);

    const [service, setService] = useState({
        ser_type: 1,
        ser_company: companies[0],
        ser_estructures: [],
    });

    const handleChangeService = (event) => {
        const { value, name } = event.target;
        setService((prevState) => ({
            ...prevState,
            [name]: parseInt(value),
        }));
    };

    const arrEstructures = {
        1: [
            { est_description: "AINI", est_date: date },
            { est_description: "A. 1er SEGUIMIENTO", est_date: date },
            { est_description: "A. 2do SEGUIMIENTO", est_date: date },
        ],
        2: [
            { est_description: "A. 1er SEGUIMIENTO", est_date: date },
            { est_description: "A. 2do SEGUIMIENTO", est_date: date },
        ],
        3: [{ est_description: "A. Extraordinaria", est_date: date }],
        4: [{ est_description: "A. Preliminar", est_date: date }],
    };

    const submitService = async (event) => {
        event.preventDefault();
        service.ser_estructures = arrEstructures[service.ser_type];
        service.est_date = date;

        //typeSubmit === "add" ? addServiceDispatch(service) : editServiceDispatch(service);

        try {
            await addServiceDispatch(service);
        } catch (error) {}

        history.push("/estructures");
    };

    if (loadCompanies) {
        return <Loader />;
    }
    if (companies === null) {
        return <Redirect to={"/estructures"} />;
    }

    return (
        <form onSubmit={submitService} className={classes.form}>
            <Box borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR UN SERVICIO
                    </Typography>
                </Box>
                <Grid container spacing={1} alignItems="center">
                    <Box mt={2} mb={6} mx={4} width="100%">
                        <Grid item lg={12}>
                            <Grid container spacing={1}>
                                <Grid item lg={12} xl={12}>
                                    <Box ml={4}>
                                        <Typography variant="h6">Datos de Servicio</Typography>
                                    </Box>
                                </Grid>

                                <Grid item lg={7} xl={3}>
                                    <Autocomplete
                                        options={companies}
                                        getOptionLabel={(option) => option.company_name}
                                        value={service.ser_company}
                                        getOptionSelected={(option, value) =>
                                            option._id === value._id
                                        }
                                        onChange={(_, option) => {
                                            setService((prev) => ({
                                                ...prev,
                                                ser_company: option,
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <CustomField {...params} autoFocus label="Empresa" />
                                        )}
                                    />
                                </Grid>

                                <Box width="100%" />

                                <Grid item xs={12} sm={12} lg={7} xl={2}>
                                    <FormControl
                                        required
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    >
                                        <InputLabel>Tipo de Servicio</InputLabel>
                                        <Select
                                            name="ser_type"
                                            value={service.ser_type}
                                            onChange={handleChangeService}
                                        >
                                            <MenuItem value={1}>Auditoria Normal</MenuItem>
                                            <MenuItem value={2}>
                                                Auditoria de Recertificacion
                                            </MenuItem>
                                            <MenuItem value={3}>Auditoria Extraordinaria</MenuItem>
                                            <MenuItem value={4}>Auditoria Preliminar</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Box width="100%" />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button
                            variant="contained"
                            // color="secondary"
                            onClick={() => history.push("/estructures")}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            // disabled={typeSubmit === "show"}
                        >
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditService;
