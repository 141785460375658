import React from "react";

//styles and materials
import { TableHead, TableCell, TableRow } from "@material-ui/core";
import useStyles from "../../../styles/Styles";
import { Tooltip, IconButton } from "@material-ui/core";

import AddCircleIcon from "@material-ui/icons/AddCircle";

const TableDetractionsHeader = ({ selectAndOpen, sale }) => {
    const classes = useStyles();
    return (
        <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={4}>
                    <div className={classes.centered}>
                        <div>DETRACCIÓN</div>
                        <div style={{ position: "absolute", right: 10 }}>
                            <Tooltip title="Agregar Detracción">
                                <IconButton onClick={() => selectAndOpen(sale, "add")}>
                                    <AddCircleIcon className={classes.iconAdd} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow className={classes.headTable}>
                <TableCell align="center" padding="none">
                    %
                </TableCell>
                <TableCell align="center" padding="none">
                    MONTO DETRACCIÓN
                </TableCell>
                <TableCell align="center" padding="none">
                    TASA DE CAMBIO
                </TableCell>
                <TableCell align="center" padding="none">
                    MONTO CALCULADO
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TableDetractionsHeader;
