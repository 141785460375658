import React from "react";

//styles and materials
import { TableCell, TableRow, TableBody, Tooltip, IconButton } from "@material-ui/core";
import { formatedMoney, localeDate } from "../../../utils/functions";
import useStyles from "../../../styles/Styles";

import { Edit } from "@material-ui/icons";

const TableDetractionsBodyRow = ({ sale, selectAndOpen }) => {
    const classes = useStyles();

    let symbolMoney = sale?.sale_type_money === "Soles" ? "S/. " : "$ ";

    return (
        <TableBody>
            {/* DATOS DE DETRACCIÓN */}
            <TableRow>
                <TableCell align="center">{sale.sale_percent_det}</TableCell>
                <TableCell align="right">
                    {symbolMoney + formatedMoney(sale.sale_amount_det)}
                </TableCell>
                <TableCell align="right">{sale.sale_exchange_rate_det}</TableCell>
                <TableCell align="right">
                    {"S/. " + formatedMoney(sale.sale_amount_changed_det)}
                </TableCell>
            </TableRow>

            {/* HEADER DE PAGOS DE DETRACCIONES */}
            <TableRow className={classes.colorCell}>
                <TableCell />
                <TableCell align="center" padding="none">
                    FECHA
                </TableCell>
                <TableCell align="center" padding="none">
                    MONTO PAGADO
                </TableCell>
                <TableCell align="center" padding="none">
                    ACCIONES
                </TableCell>
            </TableRow>

            {/* LISTA DE DETRACCIONES */}
            {sale.sale_detractions.map((detraction) => (
                <TableRow key={detraction._id}>
                    <TableCell />
                    <TableCell align="center">{localeDate(detraction?.detraction_date)}</TableCell>
                    <TableCell align="right">
                        {formatedMoney(detraction?.detraction_amount || 0)}
                    </TableCell>
                    <TableCell align="center">
                        <Tooltip title="Editar">
                            <IconButton
                                size="small"
                                onClick={() => selectAndOpen(detraction, "edit")}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ))}

            <TableRow>
                <TableCell align="right" colSpan={2}>
                    <b>Total: </b>
                </TableCell>
                <TableCell align="right">
                    <b>
                        {formatedMoney(
                            sale.sale_detractions?.reduce(
                                (total, value) => total + value.detraction_amount,
                                0
                            ) || 0
                        )}
                    </b>
                </TableCell>
                <TableCell />
            </TableRow>

            <TableRow>
                <TableCell align="right" colSpan={2}>
                    <b>Diferencia: </b>
                </TableCell>
                <TableCell align="right">
                    <b>
                        {formatedMoney(
                            sale.sale_amount_changed_det -
                                sale.sale_detractions?.reduce(
                                    (total, value) => total + value.detraction_amount,
                                    0
                                ) || 0
                        )}
                    </b>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableBody>
    );
};

export default TableDetractionsBodyRow;
