import { ActionTypes } from "../types/action.types";

const initialState = {
    dollarRate: {},
};
export const sunatReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DOLLAR_RATE:
            return { ...state, dollarRate: action.payload };

        default:
            return state;
    }
};
