import React from "react";

//styles and material
import { Drawer, IconButton, List } from "@material-ui/core";
import useStyles from "../../styles/navStyles";

//icons
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

//components
import ListItems from "./ListItems";

const Sidebar = ({ open, cambiarOpen, variant }) => {
    const classes = useStyles();

    return (
        <Drawer
            className={classes.drawer}
            variant={variant}
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
            color="primary"
            onClose={cambiarOpen}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={cambiarOpen}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>

            <List dense component="nav">
                <ListItems />
            </List>
        </Drawer>
    );
};

export default Sidebar;
