import React, { useState } from "react";

//styles and materials
import useStyles from "../../styles/Styles";
import { Button, Grid, Typography, Box } from "@material-ui/core";
//redux
import { addCompanyAction, editCompanyAction } from "../../redux/actions/company.action";
import { useDispatch, useSelector } from "react-redux";

import CustomField from "../customComponents/CustomField";

const AddCompany = ({ typeSubmit, openCloseAndType }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const addCompanyDispatch = (data) => dispatch(addCompanyAction(data));
    const editCompanyDispatch = (data) => dispatch(editCompanyAction(data));

    const selectedCompany = useSelector((state) => state.companies.selectedCompany);

    const [company, setCompany] = useState(typeSubmit === "edit" ? selectedCompany : {});

    const handleChange = (event) => {
        const { value, name } = event.target;
        setCompany((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitAddCompany = (event) => {
        event.preventDefault();
        typeSubmit === "add" ? addCompanyDispatch(company) : editCompanyDispatch(company);
        openCloseAndType("");
    };

    return (
        <form onSubmit={submitAddCompany} className={classes.form}>
            <Box mb={4} borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR EMPRESA
                    </Typography>
                </Box>

                <Box pb={4} m={4}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                autoFocus
                                name="company_name"
                                label="Razón Social"
                                type="text"
                                value={company.company_name || ""}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="company_ruc"
                                label="RUC"
                                value={company.company_ruc || ""}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="company_email"
                                label="Correo"
                                type="email"
                                value={company.company_email || ""}
                                onChange={handleChange}
                                required={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="company_phone"
                                label="Teléfono"
                                type="text"
                                value={company.company_phone || ""}
                                onChange={handleChange}
                                required={false}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="company_contact"
                                label="Contacto"
                                type="text"
                                value={company.company_contact || ""}
                                onChange={handleChange}
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" onClick={() => openCloseAndType("")}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddCompany;
