import React from "react";
import { useHistory } from "react-router-dom";

//styles and materials
import { Table, Paper, TableContainer } from "@material-ui/core";

//redux
import { useDispatch } from "react-redux";
import { selectPaymentAction } from "../../../redux/actions/payment.action";
import { selectSaleAction } from "../../../redux/actions/sale.action";

//componentes
import TablePaymentsHeader from "./TablePaymentsHeader";
import TablePaymentsBodyRows from "./TablePaymentsBodyRows";

const TablePayments = ({ sale }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const selectPaymentDispatch = (data) => dispatch(selectPaymentAction(data));
    const selectSaleDispatch = (data) => dispatch(selectSaleAction(data));

    const selectAndOpen = (dataSale, dataPay, type) => {
        type === "add" && selectSaleDispatch(dataSale) && history.push("/salesbr/payment/add");
        type === "edit" &&
            selectSaleDispatch(dataSale) &&
            selectPaymentDispatch(dataPay) &&
            history.push("/salesbr/payment/edit");
    };

    return (
        <TableContainer style={{ width: "50%", marginRight: "30px" }} component={Paper}>
            <Table size="small">
                <TablePaymentsHeader sale={sale} selectAndOpen={selectAndOpen} />
                <TablePaymentsBodyRows selectAndOpen={selectAndOpen} sale={sale} />
            </Table>
        </TableContainer>
    );
};

export default TablePayments;
