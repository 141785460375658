import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

//styles and materials
import useStyles from "../../../styles/Styles";
import { Button, InputAdornment, Box, Typography, Grid } from "@material-ui/core";

import {
    addDetracctionAction,
    editDetracctionAction,
} from "../../../redux/actions/detraction.action";
import { useDispatch, useSelector } from "react-redux";

import { date, formatedMoney, removeChars } from "../../../utils/functions";

import CustomField from "../../customComponents/CustomField";

const AddEditDetraction = ({ typeSubmit = "add" }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const addDetractionDispatch = (data) => dispatch(addDetracctionAction(data));
    const editDetractionDispatch = (data) => dispatch(editDetracctionAction(data));

    const selectedSale = useSelector((state) => state.sales.selectedSale);
    const selectedDetraction = useSelector((state) => state.detractions.selectedDetraction);

    const [detraction, setDetraction] = useState(
        typeSubmit === "edit"
            ? selectedDetraction
            : {
                  detraction_amount: 0,
                  detraction_date: date,
                  detraction_state: "Detracción",
              }
    );

    let symbolMoney = selectedSale?.sale_type_money === "Soles" ? "S/. " : "$ ";

    const handleChange = (event) => {
        const { value, name } = event.target;
        setDetraction((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeMoney = (event) => {
        const { value, name } = event.target;
        const valueFormated = removeChars(value);

        setDetraction((prevState) => ({
            ...prevState,
            [name]: valueFormated,
        }));
    };

    const handleBlur = (event) => {
        const { value, name } = event.target;
        const valueFormated = removeChars(value);

        setDetraction((prevState) => ({
            ...prevState,
            [name]: parseFloat(valueFormated),
        }));
    };

    const submitDetraction = (event) => {
        event.preventDefault();

        let dataOut = detraction;
        typeSubmit === "add" && (dataOut.detraction_sale = selectedSale._id);

        dataOut.detraction_amount === null && (dataOut.detraction_amount = 0);

        dataOut.detraction_amount = removeChars(formatedMoney(detraction.detraction_amount));

        typeSubmit === "add" ? addDetractionDispatch(dataOut) : editDetractionDispatch(dataOut);

        history.push("/sales");
    };

    if (typeSubmit === "add" && selectedSale === null) {
        return <Redirect to={"/sales"} />;
    }

    if (typeSubmit === "edit" && selectedDetraction === null) {
        return <Redirect to={"/sales"} />;
    }

    return (
        <form onSubmit={submitDetraction} className={classes.form}>
            <Box mb={4} borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR DETRACCIÓN
                    </Typography>
                </Box>

                <Box pb={4} m={4}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Fecha de Pago"
                                type="date"
                                name="detraction_date"
                                value={detraction.detraction_date}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Monto"
                                type="text"
                                name="detraction_amount"
                                value={formatedMoney(detraction.detraction_amount)}
                                onChange={handleChangeMoney}
                                onBlur={handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {symbolMoney}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" onClick={() => history.push("/sales")}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditDetraction;
