import React from "react";
import clsx from "clsx";

//styles and materials
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Box,
} from "@material-ui/core";
import useStyles from "../../../styles/Styles";

//utils
import { formatedMoney, removeChars } from "../../../utils/functions";

//components
import CustomField from "../../customComponents/CustomField";

const InformationRow = ({ estructure, setEstructure, typeSubmit }) => {
    const classes = useStyles();

    const handleChangeValueDay = (event) => {
        const { value, name } = event.target;
        const valueFormated = name === "est_type_money" ? value : removeChars(value);

        const resultValue =
            (name === "est_day_value_auditor" && valueFormated * estructure.est_total_days) ||
            (name === "est_total_days" && estructure.est_day_value_auditor * valueFormated) ||
            estructure.est_day_value_auditor * estructure.est_total_days;

        const net_value = resultValue / 1.18;

        const exchange =
            name === "est_exchange_rate" ? valueFormated : estructure.est_exchange_rate;

        const valueDolares = resultValue / exchange;

        const imprevist_unforence = (resultValue * estructure.est_impre_costs_unfo_percent) / 100;
        const imprevist_guarantee =
            (resultValue * estructure.est_impre_costs_guarant_percent) / 100;
        const imprevist_total = imprevist_unforence + imprevist_guarantee;

        const abc =
            estructure.est_direct_costs_total +
            estructure.est_variable_costs_total +
            imprevist_total;

        const financial_provision = ((net_value - abc) * estructure.est_finan_provi_percent) / 100;
        const financial_profit = (net_value * estructure.est_finan_profit_percent) / 100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total =
            resultValue - net_value + (resultValue * estructure.est_tax_export_percent) / 100;

        setEstructure((prevState) => ({
            ...prevState,
            [name]: valueFormated,
            est_igv_sol:
                estructure.est_type_money === "Soles"
                    ? resultValue - net_value
                    : (resultValue - net_value) * exchange,
            est_igv_dolar:
                estructure.est_type_money === "Dolares"
                    ? resultValue - net_value
                    : valueDolares - valueDolares / 1.18,
            est_sale_value_sol:
                estructure.est_type_money === "Soles" ? net_value : net_value * exchange,
            est_sale_value_dolar:
                estructure.est_type_money === "Dolares" ? net_value : valueDolares / 1.18,
            est_total_sale_sol:
                estructure.est_type_money === "Soles" ? resultValue : resultValue * exchange,
            est_total_sale_dolar:
                estructure.est_type_money === "Dolares" ? resultValue : valueDolares,

            est_impre_costs_unfo: imprevist_unforence,
            est_impre_costs_guarant: imprevist_guarantee,
            est_impre_cost_total: imprevist_total,

            est_sum_total_abc: abc,
            est_finan_pre_service: net_value - abc,
            est_finan_provi: financial_provision,
            est_finan_profit: financial_profit,
            est_finan_profit_real: financial_profit_real,

            est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * estructure.est_tax_export_percent) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total || 0.0,
        }));
    };

    const handleChangeTypeMoney = (event) => {
        const { value, name } = event.target;
        const resultValue = estructure.est_day_value_auditor * estructure.est_total_days;
        const net_value = resultValue / 1.18;
        const exchange = estructure.est_exchange_rate;
        const valueDolares = resultValue / exchange;

        setEstructure((prevState) => ({
            ...prevState,
            [name]: value,
            est_igv_sol:
                value === "Soles" ? resultValue - net_value : (resultValue - net_value) * exchange,
            est_igv_dolar:
                value === "Dolares" ? resultValue - net_value : valueDolares - valueDolares / 1.18,
            est_sale_value_sol: value === "Soles" ? net_value : net_value * exchange,
            est_sale_value_dolar: value === "Dolares" ? net_value : valueDolares / 1.18,
            est_total_sale_sol: value === "Soles" ? resultValue : resultValue * exchange,
            est_total_sale_dolar: value === "Dolares" ? resultValue : valueDolares,
        }));
    };

    const handleBlur = (event) => {
        const { value, name } = event.target;
        const valueFormated = removeChars(value);
        setEstructure((prevState) => ({
            ...prevState,
            [name]: parseFloat(valueFormated),
        }));
    };

    return (
        <>
            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <CustomField
                    label="Días de Auditoría"
                    type="text"
                    name="est_total_days"
                    value={estructure.est_total_days}
                    onChange={handleChangeValueDay}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <CustomField
                    label="Valor día Auditor"
                    name="est_day_value_auditor"
                    value={formatedMoney(estructure.est_day_value_auditor)}
                    onChange={handleChangeValueDay}
                    onBlur={handleBlur}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={2} xl={2}>
                <CustomField
                    label="Tasa de Cambio"
                    name="est_exchange_rate"
                    value={estructure.est_exchange_rate}
                    onChange={handleChangeValueDay}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Grid item xs={12} sm={6} lg={3} xl={2}>
                <FormControl
                    required
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    disabled={typeSubmit === "show"}
                >
                    <InputLabel>Tipo de Moneda</InputLabel>
                    <Select
                        name="est_type_money"
                        value={estructure.est_type_money}
                        onChange={handleChangeTypeMoney}
                    >
                        <MenuItem value="Soles">Soles</MenuItem>
                        <MenuItem value="Dolares">Dolares</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="IGV"
                    type="text"
                    name="est_igv_sol"
                    value={formatedMoney(estructure.est_igv_sol)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                    }}
                    className={clsx(estructure.est_type_money === "Soles" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="Valor"
                    type="text"
                    name="est_sale_value_sol"
                    value={formatedMoney(estructure.est_sale_value_sol)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                    }}
                    className={clsx(estructure.est_type_money === "Soles" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_total_sale_sol"
                    value={formatedMoney(estructure.est_total_sale_sol)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">S/.</InputAdornment>,
                    }}
                    className={clsx(estructure.est_type_money === "Soles" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="IGV"
                    type="text"
                    name="est_igv_dolar"
                    value={formatedMoney(estructure.est_igv_dolar)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$ &nbsp;&nbsp;</InputAdornment>
                        ),
                    }}
                    className={clsx(estructure.est_type_money === "Dolares" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="Valor"
                    type="text"
                    name="est_sale_value_dolar"
                    value={formatedMoney(estructure.est_sale_value_dolar)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$ &nbsp;&nbsp;</InputAdornment>
                        ),
                    }}
                    className={clsx(estructure.est_type_money === "Dolares" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_total_sale_dolar"
                    value={formatedMoney(estructure.est_total_sale_dolar)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$ &nbsp;&nbsp;</InputAdornment>
                        ),
                    }}
                    className={clsx(estructure.est_type_money === "Dolares" && classes.success)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
        </>
    );
};

export default InformationRow;
