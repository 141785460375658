import React from "react";
import { TextField } from "@material-ui/core";

const CustomField = (props) => {
    return (
        <TextField
            color="primary"
            margin="dense"
            variant="outlined"
            required
            fullWidth
            {...props}
        />
    );
};

export default CustomField;
