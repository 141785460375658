import { ActionTypes } from "../types/action.types";
import { addCompanyAPI, listCompaniesAPI, editCompanyAPI } from "../../axios/companyAPI";

//LISTADO DE EMPRESAS
const listingCompanies = () => ({
    type: ActionTypes.LISTING_COMPANIES,
    payload: true,
});

const listCompanies = (companies) => ({
    type: ActionTypes.LIST_COMPANIES,
    payload: companies,
});

const listCompaniesError = (error) => ({
    type: ActionTypes.LIST_COMPANIES_ERROR,
    payload: error,
});

export const listCompaniesAction = () => {
    return async (dispatch) => {
        dispatch(listingCompanies());

        try {
            const res = await listCompaniesAPI();
            dispatch(listCompanies(res.data));
        } catch (error) {
            dispatch(listCompaniesError(error.response.data.error));
        }
    };
};

//AGREGADO DE EMPRESAS
const addCompany = () => ({
    type: ActionTypes.ADD_COMPANY,
    payload: true,
});

const addCompanyDone = (company) => ({
    type: ActionTypes.ADD_COMPANY_DONE,
    payload: company,
});

const addCompanyError = (error) => ({
    type: ActionTypes.ADD_COMPANY_ERROR,
    payload: error,
});

export const addCompanyAction = (company) => {
    return async (dispatch) => {
        dispatch(addCompany());

        try {
            const res = await addCompanyAPI(company);

            //si todo sale bien actualizar el state
            dispatch(addCompanyDone(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(addCompanyError(error.response.data.error));
        }
    };
};

//EDICIÓN DE EMPRESA
const selectingCompany = (company) => ({
    type: ActionTypes.SELECTING_COMPANY,
    payload: company,
});

export const selectCompanyAction = (company) => {
    return async (dispatch) => {
        dispatch(selectingCompany(company));
    };
};

//EDITAR EMPRESA
const editingCompany = () => ({
    type: ActionTypes.EDITING_COMPANY,
    payload: true,
});

const editCompany = (params) => ({
    type: ActionTypes.EDIT_COMPANY_DONE,
    payload: params,
});

const editCompanyError = (error) => ({
    type: ActionTypes.EDIT_COMPANY_ERROR,
    payload: error,
});

export const editCompanyAction = (company) => {
    return async (dispatch) => {
        dispatch(editingCompany());

        try {
            const res = await editCompanyAPI(company);

            //si todo sale bien actualizar el state
            dispatch(editCompany(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editCompanyError(error.response.data.error));
        }
    };
};
//
