import { ActionTypes } from "../types/action.types";
import { addServiceAPI, listServicesAPI, editServiceAPI } from "../../axios/serviceAPI";
import { editEstructureAPI } from "../../axios/estructureAPI";

//LISTADO DE VENTAS
const listingServices = () => ({
    type: ActionTypes.LISTING_SERVICES,
    payload: true,
});

const listServices = (services) => ({
    type: ActionTypes.LIST_SERVICES,
    payload: services,
});

const listServicesError = (error) => ({
    type: ActionTypes.LIST_SERVICES_ERROR,
    payload: error,
});

export const listServicesAction = () => {
    return async (dispatch) => {
        dispatch(listingServices());

        try {
            const res = await listServicesAPI();
            // console.log(res.data);

            dispatch(listServices(res.data));
        } catch (error) {
            dispatch(listServicesError(error.response.data.error));
        }
    };
};

//AGREGADO DE VENTA
const addService = () => ({
    type: ActionTypes.ADD_SERVICE,
    payload: true,
});

const addServiceDone = (service) => ({
    type: ActionTypes.ADD_SERVICE_DONE,
    payload: service,
});

const addServiceError = (error) => ({
    type: ActionTypes.ADD_SERVICE_ERROR,
    payload: error,
});

export const addServiceAction = (service) => {
    return async (dispatch) => {
        dispatch(addService());

        try {
            const res = await addServiceAPI(service);
            //si todo service bien actualizar el state
            dispatch(addServiceDone(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(addServiceError(error.response.data.error));
        }
    };
};

//SELECCIÓN DE VENTA
const selectingService = (service) => ({
    type: ActionTypes.SELECTING_SERVICE,
    payload: service,
});

export const selectServiceAction = (service) => {
    return async (dispatch) => {
        dispatch(selectingService(service));
    };
};

//EDITAR VENTA
const editingService = () => ({
    type: ActionTypes.EDITING_SERVICE,
    payload: true,
});

const editService = (params) => ({
    type: ActionTypes.EDIT_SERVICE_DONE,
    payload: params,
});

const editServiceError = (error) => ({
    type: ActionTypes.EDIT_SERVICE_ERROR,
    payload: error,
});

export const editServiceAction = (service) => {
    return async (dispatch) => {
        dispatch(editingService());

        try {
            const res = await editServiceAPI(service);

            //si todo service bien actualizar el state
            dispatch(editService(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editServiceError(error.response.data.error));
        }
    };
};

//EDITAR VENTA
const editingEstructure = () => ({
    type: ActionTypes.EDITING_ESTRUCTURE,
    payload: true,
});

const editEstructure = (params) => ({
    type: ActionTypes.EDIT_ESTRUCTURE_DONE,
    payload: params,
});

const editEstructureError = (error) => ({
    type: ActionTypes.EDIT_ESTRUCTURE_ERROR,
    payload: error,
});

export const editEstructureAction = (estructure) => {
    return async (dispatch) => {
        dispatch(editingEstructure());

        try {
            const res = await editEstructureAPI(estructure);

            //si todo estructure bien actualizar el state

            dispatch(editEstructure(res.data));
            // console.log(res.data);
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editEstructureError(error.response.data.error));
        }
    };
};
