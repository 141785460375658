import React from "react";

//styles and materials
import { TableHead, TableCell, TableRow } from "@material-ui/core";
import useStyles from "../../styles/Styles";

const TableEstructuresHeader = () => {
    const classes = useStyles();

    return (
        <TableHead className={classes.stickyHeadTable}>
            <TableRow className={classes.paddingCell}>
                <TableCell align="center">RAZÓN SOCIAL</TableCell>
                <TableCell align="center">TIPO DE SERVICIO</TableCell>
                <TableCell align="center">ESTRUCTURAS</TableCell>

                <TableCell align="center">ACCIONES</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TableEstructuresHeader;
