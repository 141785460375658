import React from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";

//styles and material
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";

//icons
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PaymentIcon from "@material-ui/icons/Payment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import FlagIcon from "@material-ui/icons/Flag";

import * as colors from "@material-ui/core/colors";

//redux
import { useDispatch } from "react-redux";
import { deleteAuthAction } from "../../redux/actions/auth.action";

const useStyles = makeStyles((theme) => ({
    lista: {
        margin: "3px 16px !important",
        width: "83% !important",
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },
    listCollapse: {
        color: colors.amber[400],
    },
}));

const Listbar = () => {
    const classes = useStyles();
    let { pathname } = useLocation();

    const dispatch = useDispatch();
    const deleteAuthDispatch = () => dispatch(deleteAuthAction());

    const [open, setOpen] = React.useState(pathname.includes("/sales") || false);

    const handleClick = () => {
        setOpen(!open);
    };

    const collapse = clsx(open && classes.listCollapse);

    return (
        <>
            <ListItem
                button
                component={Link}
                to="/companies"
                selected={pathname.includes("/companies")}
            >
                <ListItemIcon>
                    <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="EMPRESAS" />
                {pathname.includes("/companies") && <ListItemText inset primary="●" />}
            </ListItem>

            <ListItem
                button
                component={Link}
                to="/sellers"
                selected={pathname.includes("/sellers")}
            >
                <ListItemIcon>
                    <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText primary="VENDEDORES" />
                {pathname.includes("/sellers") && <ListItemText inset primary="●" />}
            </ListItem>

            {/* <ListItem button component={Link} to="/sales" selected={pathname.includes("/sales")}>
                <ListItemIcon>
                    <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="COBRANZAS" />
                {pathname.includes("/sales") && <ListItemText inset primary="●" />}
            </ListItem> */}

            <ListItem button onClick={handleClick} className={collapse}>
                <ListItemIcon>
                    <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="COBRANZAS" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List dense component="div" disablePadding className={classes.lista}>
                    <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/sales"
                        selected={pathname === "/sales" || pathname.includes("/sales/")}
                    >
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="PERÚ" />
                        {(pathname === "/sales" || pathname.includes("/sales/")) && (
                            <ListItemText inset primary="●" />
                        )}
                    </ListItem>
                    {/*<ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/salesbr"
                        selected={pathname === "/salesbr" || pathname.includes("/salesbr/")}
                    >
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="BRASIL" />
                        {(pathname === "/salesbr" || pathname.includes("/salesbr/")) && (
                            <ListItemText inset primary="●" />
                        )}
                    </ListItem>*/}
                </List>
            </Collapse>

            <ListItem
                button
                component={Link}
                to="/estructures"
                selected={pathname.includes("/estructures")}
            >
                <ListItemIcon>
                    <LocalAtmIcon />
                </ListItemIcon>
                <ListItemText primary="ESTRUCTURAS" />
                {pathname.includes("/estructures") && <ListItemText inset primary="●" />}
            </ListItem>

            <ListItem
                button
                style={{ marginTop: "25px" }}
                onClick={() => {
                    localStorage.removeItem("token");
                    deleteAuthDispatch();
                }}
                component={Link}
                to="/"
            >
                <ListItemIcon>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="DESCONECTAR" />
            </ListItem>
        </>
    );
};

export default Listbar;
