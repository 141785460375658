export const ActionTypes = {
    //AUTENTICACION

    SET_AUTH: "SET_AUTH",
    SET_NOT_AUTH: "SET_NOT_AUTH",
    SET_AUTH_DONE: "SET_AUTH_DONE",
    SET_AUTH_ERROR: "SET_AUTH_ERROR",
    SET_AUTH_DELETE: "SET_AUTH_DELETE",

    //COMPANIES

    LISTING_COMPANIES: "LISTING_COMPANIES",
    LIST_COMPANIES: "LIST_COMPANIES",
    LIST_COMPANIES_ERROR: "LIST_COMPANIES_ERROR",

    ADD_COMPANY: "ADD_COMPANY",
    ADD_COMPANY_DONE: "ADD_COMPANY_DONE",
    ADD_COMPANY_ERROR: "ADD_COMPANY_ERROR",

    SELECTING_COMPANY: "SELECTING_COMPANY",
    EDITING_COMPANY: "EDITING_COMPANY",
    EDIT_COMPANY_DONE: "EDIT_COMPANY_DONE",
    EDIT_COMPANY_ERROR: "EDIT_COMPANY_ERROR",

    // VENDEDORES
    LISTING_SELLERS: "LISTING_SELLERS",
    LIST_SELLERS: "LIST_SELLERS",
    LIST_SELLERS_ERROR: "LIST_SELLERS_ERROR",

    ADD_SELLER: "ADD_SELLER",
    ADD_SELLER_DONE: "ADD_SELLER_DONE",
    ADD_SELLER_ERROR: "ADD_SELLER_ERROR",

    SELECTING_SELLER: "SELECTING_SELLER",
    EDITING_SELLER: "EDITING_SELLER",
    EDIT_SELLER_DONE: "EDIT_SELLER_DONE",
    EDIT_SELLER_ERROR: "EDIT_SELLER_ERROR",

    //SALES
    LISTING_SALES: "LISTING_SALES",
    LIST_SALES: "LIST_SALES",
    LIST_SALES_ERROR: "LIST_SALES_ERROR",

    ADD_SALE: "ADD_SALE",
    ADD_SALE_DONE: "ADD_SALE_DONE",
    ADD_SALE_ERROR: "ADD_SALE_ERROR",

    SELECTING_SALE: "SELECTING_SALE",
    EDITING_SALE: "EDITING_SALE",
    EDIT_SALE_DONE: "EDIT_SALE_DONE",
    EDIT_SALE_ERROR: "EDIT_SALE_ERROR",

    OPENCLOSE_SALE_LIST: "OPENCLOSE_SALE_LIST",

    SCROLL_TABLE_SALE: "SCROLL_TABLE_SALE",

    //PAYMENTS
    ADD_PAYMENT_OF_SALE: "ADD_PAYMENT_OF_SALE",
    SELECTING_PAYMENT: "SELECTING_PAYMENT",
    EDIT_PAYMENT_OF_SALE: "EDIT_PAYMENT_OF_SALE",

    //DETRACTIONS
    ADD_DETRACTION_OF_SALE: "ADD_DETRACTION_OF_SALE",
    SELECTING_DETRACTION: "SELECTING_DETRACTION",
    EDIT_DETRACTION_OF_SALE: "EDIT_DETRACTION_OF_SALE",

    //ESTRUCTURES
    LISTING_ESTRUCTURES: "LISTING_ESTRUCTURES",
    LIST_ESTRUCTURES: "LIST_ESTRUCTURES",
    LIST_ESTRUCTURES_ERROR: "LIST_ESTRUCTURES_ERROR",

    ADDING_ESTRUCTURE: "ADD_ESTRUCTURE",
    ADD_ESTRUCTURE_DONE: "ADD_ESTRUCTURE_DONE",
    ADD_ESTRUCTURE_ERROR: "ADD_ESTRUCTURE_ERROR",

    SELECTING_ESTRUCTURE: "SELECTING_ESTRUCTURE",
    EDITING_ESTRUCTURE: "EDITING_ESTRUCTURE",
    EDIT_ESTRUCTURE_DONE: "EDIT_ESTRUCTURE_DONE",
    EDIT_ESTRUCTURE_ERROR: "EDIT_ESTRUCTURE_ERROR",

    OPENCLOSE_ESTRUCTURE_LIST: "OPENCLOSE_ESTRUCTURE_LIST",

    //BANK
    LISTING_BANKS: "LISTING_BANKS",
    LIST_BANKS: "LIST_BANKS",
    LIST_BANKS_ERROR: "LIST_BANKS_ERROR",

    SELECTING_BANK: "SELECTING_BANK",

    //SUNAT
    DOLLAR_RATE: "DOLLAR_RATE",

    //SERVICE
    LISTING_SERVICES: "LISTING_SERVICES",
    LIST_SERVICES: "LIST_SERVICES",
    LIST_SERVICES_ERROR: "LIST_SERVICES_ERROR",

    ADD_SERVICE: "ADD_SERVICE",
    ADD_SERVICE_DONE: "ADD_SERVICE_DONE",
    ADD_SERVICE_ERROR: "ADD_SERVICE_ERROR",

    SELECTING_SERVICE: "SELECTING_SERVICE",
    EDITING_SERVICE: "EDITING_SERVICE",
    EDIT_SERVICE_DONE: "EDIT_SERVICE_DONE",
    EDIT_SERVICE_ERROR: "EDIT_SERVICE_ERROR",

    //SALES BRASIL
    LISTING_SALES_BR: "LISTING_SALES_BR",
    LIST_SALES_BR: "LIST_SALES_BR",
    LIST_SALES_ERROR_BR: "LIST_SALES_ERROR_BR",

    ADD_SALE_BR: "ADD_SALE_BR",
    ADD_SALE_DONE_BR: "ADD_SALE_DONE_BR",
    ADD_SALE_ERROR_BR: "ADD_SALE_ERROR_BR",

    SELECTING_SALE_BR: "SELECTING_SALE_BR",
    EDITING_SALE_BR: "EDITING_SALE_BR",
    EDIT_SALE_DONE_BR: "EDIT_SALE_DONE_BR",
    EDIT_SALE_ERROR_BR: "EDIT_SALE_ERROR_BR",

    OPENCLOSE_SALE_LIST_BR: "OPENCLOSE_SALE_LIST_BR",

    SCROLL_TABLE_SALE_BR: "SCROLL_TABLE_SALE_BR",

    //PAYMENTS BRASIL
    ADDING_PAYMENT_BR: "ADDING_PAYMENT_BR",
    ADD_PAYMENT_OF_SALE_BR: "ADD_PAYMENT_OF_SALE_BR",
    ADD_PAYMENT_ERROR_BR: "ADD_PAYMENT_ERROR_BR",

    SELECTING_PAYMENT_BR: "SELECTING_PAYMENT_BR",

    EDITING_PAYMENT_BR: "EDITING_PAYMENT_BR",
    EDIT_PAYMENT_OF_SALE_BR: "EDIT_PAYMENT_OF_SALE_BR",
    EDIT_PAYMENT_ERROR_BR: "EDIT_PAYMENT_ERROR_BR",
};
