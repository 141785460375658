import axios from "axios";

let axiosToken = axios.create({
    baseURL: process.env.REACT_APP_COST_URL_API,
});

axiosToken.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

let axiosNoToken = axios.create({
    baseURL: process.env.REACT_APP_COST_URL_API,
});

export { axiosToken, axiosNoToken };
