import React, { useState } from "react";

//styles and materials
import useStyles from "../../styles/Styles";
import { TextField, Container, Button } from "@material-ui/core";

//redux
import { addAuthAction } from "../../redux/actions/auth.action";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../loader/Loader";

const Login = () => {
    const classes = useStyles();

    const [user, setUser] = useState({});

    const dispatch = useDispatch();
    const addAuthDispatch = (data) => dispatch(addAuthAction(data));

    const loading = useSelector((state) => state.auth.loading);

    const handleChange = (e) => {
        setUser((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const signIn = (e) => {
        e.preventDefault();
        addAuthDispatch(user);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Container className={classes.container} maxWidth="md">
            <div className={classes.paper}>
                <h1 style={{ fontSize: "45px" }}>SISTEMA DE CONTABILIDAD</h1>
                <div>
                    <form onSubmit={signIn} className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Correo"
                            name="user_email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="user_password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                        />
                        <div>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.submit}
                            >
                                ACCEDER
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Container>
    );
};

export default Login;
