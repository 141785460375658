import React from "react";

//styles and materials
import { TableHead, TableCell, TableRow } from "@material-ui/core";
import useStyles from "../../styles/Styles";

const TableSalesHeader = ({ hideColumns }) => {
    const classes = useStyles();

    return (
        <TableHead className={classes.stickyHeadTable}>
            <TableRow className={classes.paddingCell}>
                <TableCell />
                <TableCell align="center">N° SERIE</TableCell>
                {hideColumns("FECHA E.") && <TableCell align="center">FECHA E.</TableCell>}
                {hideColumns("FECHA V.") && <TableCell align="center">FECHA V.</TableCell>}
                {hideColumns("TIPO C.") && <TableCell align="center">TIPO C.</TableCell>}
                <TableCell align="center">RUC</TableCell>
                <TableCell className={classes.noWrap} align="center">
                    RAZÓN SOCIAL
                </TableCell>
                {hideColumns("CONTACTO") && <TableCell align="center">CONTACTO</TableCell>}
                <TableCell align="center">VENDEDOR</TableCell>
                {hideColumns("DESCRIPCIÓN") && <TableCell align="center">DESCRIPCIÓN</TableCell>}
                <TableCell align="center">ESTADO DE PAGO</TableCell>
                <TableCell align="center">MONEDA</TableCell>
                <TableCell align="center">TOTAL</TableCell>
                {hideColumns("OBSERVACIÓN") && <TableCell align="center">OBS</TableCell>}
                <TableCell align="center">ACCIONES</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default TableSalesHeader;
