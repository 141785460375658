import React from "react";

const IconDetraction = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 50.000000 50.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.54)", padding: "2px" }}
        >
            <g transform="translate(0,50) scale(0.100000,-0.100000)" fill="white" stroke="none">
                <path
                    d="M65 458 c-3 -7 -4 -106 -3 -218 l3 -205 84 -3 c205 -7 285 54 285
218 1 96 -28 153 -94 189 -38 21 -61 26 -157 29 -90 4 -114 2 -118 -10z m226
-75 c38 -23 59 -70 59 -133 0 -102 -46 -150 -144 -150 l-56 0 0 150 0 150 56
0 c35 0 67 -6 85 -17z"
                />
            </g>
        </svg>
    );
};

export default IconDetraction;
