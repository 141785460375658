import { ActionTypes } from "../types/action.types";
import { addPaymentAPI, editPaymentAPI } from "../../axios/paymentAPI";

//AGREGADO DE PAGO
const addSale = () => ({
    type: ActionTypes.ADD_SALE,
    payload: true,
});

//DENTRO DEL REDUCER DE SALE
const addPaymentToSaleDone = (pay) => ({
    type: ActionTypes.ADD_PAYMENT_OF_SALE,
    payload: pay,
});

const addSaleError = (error) => ({
    type: ActionTypes.ADD_SALE_ERROR,
    payload: error,
});

export const addPaymentAction = (pay) => {
    return async (dispatch) => {
        dispatch(addSale());

        try {
            const res = await addPaymentAPI(pay);
            dispatch(addPaymentToSaleDone(res.data));
        } catch (error) {
            dispatch(addSaleError(error));
            // console.log(error);
        }
    };
};

//SELECCIÓN DE VENTA
const selectingPayment = (pay) => ({
    type: ActionTypes.SELECTING_PAYMENT,
    payload: pay,
});

export const selectPaymentAction = (pay) => {
    return async (dispatch) => {
        dispatch(selectingPayment(pay));
    };
};

//
//
//EDITAR PAGO
const editingPayment = () => ({
    type: ActionTypes.EDITING_SALE,
    payload: true,
});

const editPayment = (pay) => ({
    type: ActionTypes.EDIT_PAYMENT_OF_SALE,
    payload: pay,
});

const editPaymentError = (error) => ({
    type: ActionTypes.EDIT_SALE_ERROR,
    payload: error,
});

export const editPaymentAction = (pay) => {
    return async (dispatch) => {
        dispatch(editingPayment());

        try {
            const res = await editPaymentAPI(pay);
            dispatch(editPayment(res.data));
        } catch (error) {
            dispatch(editPaymentError(error));
        }
    };
};
