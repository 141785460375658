import React, { useState } from "react";

//styles and material
import {
    Menu,
    MenuItem,
    Checkbox,
    FormControlLabel,
    IconButton,
    Tooltip,
    Badge,
} from "@material-ui/core";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";

const Compnay = ({ infoColumns, setinfoColumns }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setinfoColumns(infoColumns.map((e) => (e.name === name ? { ...e, open: checked } : e)));
    };

    const optionsChecked = infoColumns.filter((e) => e.open).length;

    return (
        <>
            <Tooltip title="Mostrar / Ocultar Columnas">
                <IconButton
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <Badge color="primary" badgeContent={optionsChecked}>
                        <ViewWeekOutlinedIcon color="primary" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {infoColumns.map((col, index) => (
                    <MenuItem key={index} onChange={handleCheck}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={col.open}
                                    value={index}
                                    name={col.name}
                                />
                            }
                            label={col.name}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default Compnay;
