import React from "react";

//styles and materials
import { TableCell, TableRow, Tooltip, IconButton, Chip, Box } from "@material-ui/core";
import useStyles from "../../styles/Styles";

//icons
import { Edit } from "@material-ui/icons";
import { Fragment } from "react";

const TableEstructuresBodyRow = ({ selectAndOpen, service }) => {
    const classes = useStyles();

    const arrEstructures = {
        1: "Auditoria Normal",
        2: "Auditoria de Recertificacion",
        3: "Auditoria Extraordinaria",
        4: "Auditoria Preliminar",
    };

    return (
        <TableRow className={classes.rootTable}>
            <TableCell>{service?.ser_company?.company_name}</TableCell>
            <TableCell>{arrEstructures[service?.ser_type]}</TableCell>
            <TableCell>
                <Box className={classes.chip}>
                    {service?.ser_estructures.map((e) => (
                        <Fragment key={e._id}>
                            <Chip
                                size="small"
                                label={e.est_description}
                                style={{
                                    color: e.est_actived ? "green" : "gray",
                                    background: e.est_actived ? "#8effad" : "#dbdbdb",
                                    fontWeight: "bold",
                                }}
                            />
                        </Fragment>
                    ))}
                </Box>
            </TableCell>

            <TableCell align="center" className={classes.noWrap}>
                <Tooltip title="Editar">
                    <IconButton size="small" onClick={() => selectAndOpen(service, "edit")}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default TableEstructuresBodyRow;
