import { ActionTypes } from "../types/action.types";

const initialState = {
    error: false,
    loading: false,
    selectedPayment: null,
    alertMsg: "",
};
export const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SELECTING_PAYMENT:
            return { ...state, selectedPayment: action.payload };

        default:
            return state;
    }
};
