import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import CustomField from "../../customComponents/CustomField";
import { TextField } from "@material-ui/core";

import { formatedMoney } from "../../../utils/functions";

const FinancialCostRow = ({ estructure, setEstructure, typeSubmit }) => {
    const handleChange = (event) => {
        const { value, name } = event.target;

        const nameCell =
            name === "est_finan_provi_percent" ? "financial_provision" : "financial_profit";

        const abc =
            estructure.est_direct_costs_total +
            estructure.est_variable_costs_total +
            estructure.est_impre_cost_total;
        const resultValue =
            estructure.est_type_money === "Dolares"
                ? estructure.est_total_sale_dolar
                : estructure.est_total_sale_sol;
        const net_value = resultValue / 1.18;

        const financial_provision =
            ((net_value - abc) *
                (nameCell === "financial_provision" ? value : estructure.est_finan_provi_percent)) /
            100;
        const financial_profit =
            (net_value *
                (nameCell === "financial_profit" ? value : estructure.est_finan_profit_percent)) /
            100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total =
            resultValue - net_value + (resultValue * estructure.est_tax_export_percent) / 100;

        setEstructure((prevState) => ({
            ...prevState,
            [name]: value,

            est_finan_pre_service: net_value - abc,
            est_finan_provi: financial_provision,
            est_finan_profit: financial_profit,
            // [nameCell]: nameCell === "financial_provision" ? financial_provision : financial_profit,
            est_finan_profit_real: financial_profit_real,

            est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * estructure.est_tax_export_percent) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total || 0.0,
        }));
    };

    return (
        <Grid container item lg={12} spacing={1}>
            <Grid item lg={12} xl={12}>
                <Box display="flex" ml={4}>
                    <Typography variant="h6">Costos Financieros Ganancias (D)</Typography>
                </Box>
            </Grid>

            {/* TOTAL COSTOS */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    // style={{ backgroundColor: "#e7e7e4" }}
                    value="Total Costos y Gastos A+B+C"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_sum_total_abc)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* INGRESO ANTES DE PROVICIÓN */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Ingreso (Antes de Provición)" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    style={{ backgroundColor: "#e7e7e4" }}
                    margin="dense"
                    variant="outlined"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_finan_pre_service)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* PROVICIÓN */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Provición" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Porcentaje"
                    name="est_finan_provi_percent"
                    value={estructure.est_finan_provi_percent}
                    onChange={handleChange}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_finan_provi)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* GANANCIA ESTIMADA % */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Ganancia Estimada" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Porcentaje"
                    name="est_finan_profit_percent"
                    value={estructure.est_finan_profit_percent}
                    onChange={handleChange}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_finan_profit)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* GANANCIA REAL */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Ganancia Real" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_finan_profit_real)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />
        </Grid>
    );
};

export default FinancialCostRow;
