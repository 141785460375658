import React from "react";

const IconAutodetraction = () => {
    return (
        <svg
            width="18px"
            height="18px"
            viewBox="0 0 50.000000 50.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{ backgroundColor: "red", padding: "2px" }}
        >
            <g transform="translate(0,50) scale(0.100000,-0.100000)" fill="white" stroke="none">
                <path
                    d="M130 263 c-40 -115 -75 -214 -77 -220 -4 -9 7 -13 35 -13 39 0 40 1
       58 52 l19 53 85 0 85 0 19 -52 c18 -52 19 -53 58 -53 28 0 39 4 35 13 -2 6
       -37 105 -77 220 l-73 207 -47 0 -47 0 -73 -207z m152 22 l27 -75 -59 0 -59 0
       27 75 c15 41 29 75 32 75 3 0 17 -34 32 -75z"
                />
            </g>
        </svg>
    );
};

export default IconAutodetraction;
