import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import CustomField from "../../customComponents/CustomField";
import { TextField } from "@material-ui/core";

import { formatedMoney } from "../../../utils/functions";

const TaxCostRow = ({ estructure, setEstructure, typeSubmit }) => {
    const handleChange = (event) => {
        const { value, name } = event.target;

        const abc =
            estructure.est_direct_costs_total +
            estructure.est_variable_costs_total +
            estructure.est_impre_cost_total;
        const resultValue =
            estructure.est_type_money === "Dolares"
                ? estructure.est_total_sale_dolar
                : estructure.est_total_sale_sol;
        const net_value = resultValue / 1.18;

        const financial_provision = ((net_value - abc) * estructure.est_finan_provi_percent) / 100;
        const financial_profit = (net_value * estructure.est_finan_profit_percent) / 100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total = resultValue - net_value + (resultValue * value) / 100;

        setEstructure((prevState) => ({
            ...prevState,
            [name]: value,

            // est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * value) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total || 0.0,
        }));
    };

    return (
        <Grid container item lg={12} spacing={1}>
            <Grid item lg={12} xl={12}>
                <Box display="flex" ml={4}>
                    <Typography variant="h6">Impuestos del Servicio</Typography>
                </Box>
            </Grid>

            {/* IGV */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    // style={{ backgroundColor: "#e7e7e4" }}
                    value="Impuesto IGV Perú (18%)"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_tax_igv)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* EXPORTACIÓN */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Impuesto Exportación de Servicio" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Porcentaje"
                    name="est_tax_export_percent"
                    value={estructure.est_tax_export_percent}
                    onChange={handleChange}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_tax_export"
                    value={formatedMoney(estructure.est_tax_export)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            {/* 
                TOTAL
                TOTAL
                TOTAL
            */}
            <Grid item lg={5} />
            <Grid container item lg={2} xl={3} justify="center" alignItems="center">
                <Typography variant="subtitle1">TOTAL IMPUESTOS:</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_tax_total"
                    value={formatedMoney(estructure.est_tax_total)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
        </Grid>
    );
};

export default TaxCostRow;
