import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import CustomField from "../../customComponents/CustomField";
import { TextField } from "@material-ui/core";

import { formatedMoney } from "../../../utils/functions";

const ResultsCostRow = ({ estructure, typeSubmit }) => {
    return (
        <Grid container item lg={12} spacing={1}>
            <Grid item lg={12} xl={12}>
                <Box display="flex" ml={4}>
                    <Typography variant="h6">Resultados de Estructura de Costos</Typography>
                </Box>
            </Grid>

            {/* INGRESOS REALES */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    // style={{ backgroundColor: "#e7e7e4" }}
                    value="Ingresos Reales"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_real_income)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Box width="100%" />

            {/* TOTAL GASTOS */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Total Gastos A+B+C+D" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_sum_total_abcd)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Box width="100%" />

            {/* DIFERENCIA INGRESOS EGRESOS */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    // style={{ backgroundColor: "#e7e7e4" }}
                    value="Diferencia Ingresos Egresos"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_dif_income_expenses)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Box width="100%" />

            {/* PUNTO DE EQUILIBRIO */}
            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField
                    // style={{ backgroundColor: "#e7e7e4" }}
                    value="Punto de Equilibrio"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name=""
                    value={formatedMoney(estructure.est_breakeven)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Box width="100%" />
        </Grid>
    );
};

export default ResultsCostRow;
