import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import TabPanelEst from "./rows/TabPanelEstructures";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

//redux
import { useSelector } from "react-redux";

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const Estructure = () => {
    const selectedService = useSelector((state) => state.services.selectedService);
    const companies = useSelector((state) => state.companies.companies);

    const [selectedTab, setSelectedTab] = useState(selectedService?.ser_estructures[0]._id);

    //handlechange para el cambio de tabs
    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue);
    };
    const [message, setMessage] = useState({ open: false, msg: "", sev: "success" });

    const handleClose = (_event) => {
        setMessage((prev) => ({ ...prev, open: false }));
    };

    if (selectedService === null || companies.length === 0) {
        return <Redirect to={"/estructures"} />;
    }

    return (
        <Box>
            <Box width="100%">
                <Snackbar open={message.open} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={message.sev}>
                        {message.msg}
                    </Alert>
                </Snackbar>
            </Box>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab value={"back"} icon={<ArrowBackIcon />} />
                    {selectedService?.ser_estructures.map((e, i) => (
                        <Tab key={i} label={e.est_description} value={e._id} />
                    ))}
                </Tabs>
            </AppBar>

            <Box width="100%" mt={2}>
                {selectedTab === "back" && <Redirect to={"/estructures"} />}

                {selectedService?.ser_estructures.map(
                    (e, i) =>
                        selectedTab === e._id && (
                            <TabPanelEst setMessage={setMessage} key={i} est={e} />
                        )
                )}
            </Box>
        </Box>
    );
};

export default Estructure;
