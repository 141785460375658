import { axiosNoToken, axiosToken } from "./axios";

export const listServicesAPI = () => {
    return axiosNoToken.get("services");
};

export const addServiceAPI = (service) => {
    return axiosToken.post("services", service);
};

export const editServiceAPI = (service) => {
    return axiosToken.put("services/" + service._id, service);
};
