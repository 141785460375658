import React, { useState } from "react";
import ReactDOM from "react-dom";

//styles and material
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { verifyTheme } from "./theme/verifyTheme";

//redux
import { Provider } from "react-redux";
import store from "./redux/store";

//components
import App from "./App";

const WithTheme = () => {
    const [theme, setTheme] = useState(verifyTheme);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App setTheme={setTheme} />
            </ThemeProvider>
        </Provider>
    );
};

ReactDOM.render(<WithTheme />, document.getElementById("root"));
