import React, { useState } from "react";

//styles and materials
import useStyles from "../../styles/Styles";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import CustomField from "../customComponents/CustomField";

//redux
import { addSellerAction, editSellerAction } from "../../redux/actions/seller.action";
import { useDispatch, useSelector } from "react-redux";

const AddEditSeller = ({ typeSubmit, openCloseAndType }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const addSellerDispatch = (data) => dispatch(addSellerAction(data));
    const editSellerDispatch = (data) => dispatch(editSellerAction(data));

    const selectedSeller = useSelector((state) => state.sellers.selectedSeller);

    const [seller, setSeller] = useState(typeSubmit === "edit" ? selectedSeller : {});

    const handleChange = (event) => {
        const { value, name } = event.target;
        setSeller((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitAddEditSeller = (event) => {
        event.preventDefault();
        typeSubmit === "add" ? addSellerDispatch(seller) : editSellerDispatch(seller);
        openCloseAndType("");
    };

    return (
        <form onSubmit={submitAddEditSeller} className={classes.form}>
            <Box mb={4} borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR VENDEDOR
                    </Typography>
                </Box>

                <Box pb={4} m={4}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                autoFocus
                                name="seller_name"
                                label="Nombre"
                                type="text"
                                value={seller.seller_name || ""}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="seller_lastname"
                                label="Apellidos"
                                type="text"
                                value={seller.seller_lastname || ""}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                name="seller_dni"
                                label="DNI"
                                type="number"
                                value={seller.seller_dni || ""}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" onClick={() => openCloseAndType("")}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditSeller;
