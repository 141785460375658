import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

//styles and materials
import useStyles from "../../styles/Styles";
import {
    Grid,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    FormControlLabel,
    Checkbox,
    Box,
    Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Loader from "../loader/Loader";

//redux
import { addSaleAction, editSaleAction } from "../../redux/actions/sale.action";
import { useDispatch, useSelector } from "react-redux";

//utils
import { date, formatedMoney, removeChars } from "../../utils/functions";

import CustomField from "../customComponents/CustomField";

const AddEditSale = ({ typeSubmit = "add" }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const addSaleDispatch = (data) => dispatch(addSaleAction(data));
    const editSaleDispatch = (data) => dispatch(editSaleAction(data));

    const selectedSale = useSelector((state) => state.sales.selectedSale);
    const companies = useSelector((state) => state.companies.companies);
    const sellers = useSelector((state) => state.sellers.sellers);

    const loadSeller = useSelector((state) => state.sellers.loading);
    const loadCompanies = useSelector((state) => state.companies.loading);

    const [sale, setSale] = useState(
        typeSubmit === "edit"
            ? selectedSale
            : {
                  sale_country: 1,
                  sale_date_ini: date,
                  sale_date_end: date,
                  sale_description: "",
                  sale_type_voucher: "01",
                  sale_num_serie: "",
                  sale_state: "Pendiente",
                  sale_type_money: "Soles",
                  sale_seller: sellers[0],
                  sale_company: companies[0],
                  sale_sale_value: 0,
                  sale_igv: 0,
                  sale_total: 0.0,
                  sale_percent_det: 12,
                  sale_amount_det: 0,
                  sale_exchange_rate_det: 0,
                  sale_amount_changed_det: 0,
                  sale_observation: "",
                  sale_state_autodet: false,
              }
    );

    let symbolMoney = sale?.sale_type_money === "Soles" ? "S/. " : "$ ";

    const handleChange = (event) => {
        const { value, name } = event.target;
        setSale((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeTotalAmount = (event) => {
        const { value, name } = event.target;
        const valueFormated = removeChars(value);
        setSale((prevState) => ({
            ...prevState,
            [name]: valueFormated,
            sale_sale_value: valueFormated / 1.18,
            sale_igv: valueFormated - valueFormated / 1.18,
            sale_amount_det: valueFormated * (sale.sale_percent_det / 100),
            sale_amount_changed_det:
                valueFormated *
                (sale.sale_percent_det / 100) *
                (sale.sale_type_money === "Dólares" ? sale.sale_exchange_rate_det : 1),
        }));
    };

    const handleBlur = (event) => {
        const { value, name } = event.target;
        const valueFormated = removeChars(value);
        setSale((prevState) => ({
            ...prevState,
            [name]: parseFloat(valueFormated),
        }));
    };

    const handleChangePercent = (event) => {
        const { value, name } = event.target;
        setSale((prevState) => ({
            ...prevState,
            [name]: value,
            sale_amount_det: sale.sale_total * (value / 100),
            sale_amount_changed_det:
                sale.sale_total *
                (value / 100) *
                (sale.sale_type_money === "Dólares" ? sale.sale_exchange_rate_det : 1),
        }));
    };

    const handleChangeExchange = (event) => {
        const { value, name } = event.target;
        setSale((prevState) => ({
            ...prevState,
            [name]: value,
            sale_amount_changed_det: sale.sale_total * (sale.sale_percent_det / 100) * value,
        }));
    };

    const handleChangeTypeMoney = (event) => {
        const { value, name } = event.target;
        setSale((prevState) => ({
            ...prevState,
            [name]: value,
            sale_amount_changed_det:
                sale.sale_total *
                (sale.sale_percent_det / 100) *
                (value === "Dólares" ? sale.sale_exchange_rate_det : 1),
        }));
    };

    const handleCheck = (event) => {
        const { checked, name } = event.target;
        setSale((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const submitSale = (event) => {
        event.preventDefault();

        let dataOut = sale;
        sale.sale_type_money === "Soles" && (dataOut.sale_exchange_rate_det = 0);
        dataOut.sale_total = removeChars(formatedMoney(sale.sale_total));
        dataOut.sale_sale_value = removeChars(formatedMoney(sale.sale_sale_value));
        dataOut.sale_igv = removeChars(formatedMoney(sale.sale_igv));
        dataOut.sale_amount_det = removeChars(formatedMoney(sale.sale_amount_det));
        dataOut.sale_amount_changed_det = removeChars(formatedMoney(sale.sale_amount_changed_det));

        typeSubmit === "add" && (dataOut.sale_total_diference = dataOut.sale_total);
        typeSubmit === "edit" &&
            (dataOut.sale_total_diference =
                sale.sale_total -
                sale.sale_payments.reduce((total, value) => total + value.payment_amount, 0));

        typeSubmit === "add" ? addSaleDispatch(dataOut) : editSaleDispatch(dataOut);

        history.push("/sales");
    };

    if (typeSubmit === "edit" && selectedSale === null) {
        return <Redirect to={"/sales"} />;
    }

    if (loadSeller || loadCompanies) {
        return <Loader />;
    }

    return (
        <form onSubmit={submitSale} className={classes.form}>
            <Box mb={4} borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR COBRANZA
                    </Typography>
                </Box>
                <Box pb={4} m={4}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Autocomplete
                                options={companies}
                                getOptionLabel={(option) => option.company_name}
                                value={sale.sale_company}
                                getOptionSelected={(option, value) => option._id === value._id}
                                onChange={(_, option) => {
                                    setSale((prev) => ({
                                        ...prev,
                                        sale_company: option,
                                    }));
                                }}
                                renderInput={(params) => (
                                    <CustomField {...params} label="Empresa" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Autocomplete
                                options={sellers}
                                getOptionLabel={(option) =>
                                    option.seller_name + " " + option.seller_lastname
                                }
                                value={sale.sale_seller}
                                getOptionSelected={(option, value) => option._id === value._id}
                                onChange={(_, option) => {
                                    setSale((prev) => ({
                                        ...prev,
                                        sale_seller: option,
                                    }));
                                }}
                                renderInput={(params) => (
                                    <CustomField {...params} label="Vendedor" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Número de Serie"
                                type="text"
                                name="sale_num_serie"
                                value={sale.sale_num_serie}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Fecha de Emisión"
                                type="date"
                                name="sale_date_ini"
                                value={sale.sale_date_ini}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Fecha de Vencimiento"
                                type="date"
                                name="sale_date_end"
                                value={sale.sale_date_end}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Tipo de Comprobante"
                                type="number"
                                name="sale_type_voucher"
                                value={sale.sale_type_voucher}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Descripción"
                                type="text"
                                name="sale_description"
                                value={sale.sale_description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <FormControl required variant="outlined" margin="dense" fullWidth>
                                <InputLabel>Estado</InputLabel>
                                <Select
                                    name="sale_state"
                                    value={sale.sale_state}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="Pendiente">Pendiente</MenuItem>
                                    <MenuItem value="Total">Total</MenuItem>
                                    <MenuItem value="Excedido">Excedido</MenuItem>
                                    <MenuItem value="No Paga">No Paga</MenuItem>
                                    <MenuItem value="Nula">Nula</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <FormControl required variant="outlined" margin="dense" fullWidth>
                                <InputLabel>Tipo de Moneda</InputLabel>
                                <Select
                                    name="sale_type_money"
                                    value={sale.sale_type_money}
                                    onChange={handleChangeTypeMoney}
                                >
                                    <MenuItem value="Soles">Soles</MenuItem>
                                    <MenuItem value="Dólares">Dólares</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                variant="outlined"
                                margin="dense"
                                label="TOTAL"
                                type="text"
                                name="sale_total"
                                value={formatedMoney(sale.sale_total)}
                                onChange={handleChangeTotalAmount}
                                onFocus={(e) =>
                                    e.target.setSelectionRange(0, e.target.value.length - 3)
                                }
                                onBlur={handleBlur}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {symbolMoney}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                variant="outlined"
                                margin="dense"
                                label="Valor Venta"
                                type="text"
                                name="sale_sale_value"
                                value={formatedMoney(sale.sale_sale_value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {symbolMoney}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                variant="outlined"
                                margin="dense"
                                label="IGV"
                                type="text"
                                name="sale_igv"
                                value={formatedMoney(sale.sale_igv)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {symbolMoney}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Box width="100%" />
                        <Grid item xs={12} sm={6} lg={1} xl={3}>
                            <CustomField
                                label="% Det..."
                                type="text"
                                name="sale_percent_det"
                                value={sale.sale_percent_det}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                onChange={handleChangePercent}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                label="Monto de Detracción"
                                type="text"
                                name="sale_amount_det"
                                value={formatedMoney(sale.sale_amount_det)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {symbolMoney}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                disabled={sale.sale_type_money === "Soles"}
                                label="Taza de Cambio"
                                type="text"
                                name="sale_exchange_rate_det"
                                value={sale.sale_exchange_rate_det}
                                onChange={handleChangeExchange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3} xl={3}>
                            <CustomField
                                label="Monto Total Detracción"
                                type="text"
                                name="sale_amount_changed_det"
                                value={formatedMoney(sale.sale_amount_changed_det)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">S/.</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2} xl={3} align="center">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={sale.sale_state_autodet}
                                        onChange={handleCheck}
                                        name="sale_state_autodet"
                                    />
                                }
                                label="Autodetracción"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} xl={3}>
                            <CustomField
                                label="Observación"
                                type="text"
                                name="sale_observation"
                                value={sale.sale_observation}
                                onChange={handleChange}
                                required={false}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" onClick={() => history.push("/sales")}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditSale;
