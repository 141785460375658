import React from "react";
import { useHistory } from "react-router-dom";
// import clsx from "clsx";

//styles and material
import MenuIcon from "@material-ui/icons/Menu";
// import useStyles from "../../styles/navStyles";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const Navbar = ({ open, cambiarOpen }) => {
    // const classes = useStyles();

    const history = useHistory();

    const theme = useTheme();

    //abreviaciones
    //el espacio del bar que se agregar al barshift cuando open es true
    // let spaceAppBar = clsx(classes.appBar, open && classes.appBarShift);

    //el botón de menú se oculta si open es true
    // let menuVisibility = clsx(classes.menuButton, open && classes.hide);

    return (
        <AppBar
            position="fixed"
            style={
                !open
                    ? {
                          transition: theme.transitions.create(["margin", "width"], {
                              easing: theme.transitions.easing.easeOut,
                              duration: 120,
                          }),
                      }
                    : {
                          width: `calc(100% - 217px)`,
                          marginLeft: 217,
                          transition: theme.transitions.create(["margin", "width"], {
                              easing: theme.transitions.easing.easeOut,
                              duration: theme.transitions.duration.standard,
                          }),
                      }
            }
        >
            <Toolbar variant="dense">
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={cambiarOpen}
                    edge="start"
                    // className={menuVisibility}
                    style={!open ? { marginRight: theme.spacing(2) } : { display: "none" }}
                >
                    <MenuIcon />
                </IconButton>

                <Typography
                    onClick={() => history.push("/")}
                    variant="h6"
                    style={{ fontSize: "17px", cursor: "pointer" }}
                    noWrap
                >
                    LOT INTERNACIONAL
                </Typography>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
