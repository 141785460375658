import React, { useState } from "react";

//styles and material
import { Box, Button, Grid, TextField } from "@material-ui/core";
import useStyles from "../../styles/Styles";

//redux
import { useSelector, useDispatch } from "react-redux";
import { listSellersAction } from "../../redux/actions/seller.action";

//componentes
import TableSeller from "./TableSeller";
import AddEditSeller from "./AddEditSeller";
import Loader from "../loader/Loader";
import { useEffect } from "react";

const Seller = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const listSellersDispatch = () => dispatch(listSellersAction());

    const [openAddEdit, setOpenAddEdit] = useState(false);
    const [typeSubmit, setTypeSubmit] = useState("add");
    const [searcher, setSearcher] = useState("");

    const load = useSelector((state) => state.sellers.loading);
    const sellers = useSelector((state) => state.sellers.sellers);

    const filterSearch = (rows) => {
        return rows.filter(
            (row) =>
                row.seller_name.toLowerCase().indexOf(searcher.toLowerCase()) > -1 ||
                row.seller_dni.toLowerCase().indexOf(searcher.toLowerCase()) > -1
        );
    };

    const openCloseAndType = (type) => {
        setTypeSubmit(type);
        setOpenAddEdit(!openAddEdit);
    };

    useEffect(
        () => {
            !sellers.length && listSellersDispatch();
        },
        // eslint-disable-next-line
        []
    );

    if (load) {
        return <Loader />;
    }

    if (openAddEdit) {
        return <AddEditSeller typeSubmit={typeSubmit} openCloseAndType={openCloseAndType} />;
    }

    return (
        <Box>
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} lg={2} xl={2}>
                        <Button
                            fullWidth
                            size="small"
                            className={classes.fullHeight}
                            variant="contained"
                            color="primary"
                            onClick={() => openCloseAndType("add")}
                        >
                            Agregar Vendedor
                        </Button>
                    </Grid>

                    <Grid item xs={4} sm={4} lg={3} xl={2}>
                        <TextField
                            fullWidth
                            size="small"
                            name="search"
                            label="Buscador"
                            variant="outlined"
                            onChange={(e) => setSearcher(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </Box>

            <TableSeller sellers={filterSearch(sellers)} openCloseAndType={openCloseAndType} />
        </Box>
    );
};

export default Seller;
