import { axiosNoToken, axiosToken } from "./axios";

export const listSellersAPI = () => {
    return axiosNoToken.get("sellers");
};

export const addSellerAPI = (seller) => {
    return axiosToken.post("sellers", seller);
};

export const editSellerAPI = (seller) => {
    return axiosToken.put("sellers/" + seller._id, seller);
};
