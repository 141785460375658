import React from "react";

//styles and materials
import { TableCell, TableRow, Collapse, Box, Tooltip, IconButton } from "@material-ui/core";
import useStyles from "../../styles/Styles";
import * as colorui from "@material-ui/core/colors";

//icons
import { Edit } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Error } from "@material-ui/icons";

//componentes
import TablePayments from "./payments/TablePayments";

//utils
import { formatedMoney, localeDate } from "../../utils/functions";

//redux
import { openCloseRowAction } from "../../redux/actions/salebr.action";
import { useDispatch } from "react-redux";

const TableSalesBodyRow = ({ sale, selectAndOpen, hideColumns, findSaleOpened }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const openCloseDispatch = (data) => dispatch(openCloseRowAction(data));

    let colors = {
        green: colorui.green[100],
        blue: colorui.blue[200],
        red: colorui.red[200],
        white: colorui.grey[200],
        yellow: colorui.yellow[200],
    };

    const obtainStateColor = () => {
        if (sale.sale_state === "No Paga") return colors.red;
        if (sale.sale_state === "Total") return colors.green;
        if (sale.sale_state === "Excedido") return colors.blue;
        if (sale.sale_state === "Nula") return colors.yellow;
        return colors.white;
    };

    const diferenceState = () => {
        if (sale.sale_state === "No Paga" || sale.sale_state === "Nula") {
            return false;
        }

        if (sale.sale_state === "Total" && sale.sale_total_diference === 0) {
            return false;
        }
        if (sale.sale_state === "Excedido" && sale.sale_total_diference < 0) {
            return false;
        }
        if (sale.sale_state === "Pendiente" && sale.sale_total_diference > 0) {
            return false;
        }
        return true;
    };

    return (
        <>
            <TableRow className={classes.rootTable} style={{ backgroundColor: obtainStateColor() }}>
                <TableCell padding="checkbox">
                    <Tooltip title={findSaleOpened(sale) ? "Ocultar Pagos" : "Mostrar Pagos"}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => openCloseDispatch(sale)}
                        >
                            {findSaleOpened(sale) ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                </TableCell>
                <TableCell>{sale.sale_num_serie}</TableCell>
                {hideColumns("FECHA E.") && (
                    <TableCell className={classes.noWrap}>
                        {localeDate(sale.sale_date_ini)}
                    </TableCell>
                )}
                {hideColumns("FECHA V.") && (
                    <TableCell className={classes.noWrap}>
                        {localeDate(sale.sale_date_end)}
                    </TableCell>
                )}
                {hideColumns("TIPO C.") && <TableCell>{sale.sale_type_voucher}</TableCell>}
                <TableCell>{sale.sale_company?.company_ruc}</TableCell>
                <TableCell>{sale.sale_company?.company_name}</TableCell>
                {hideColumns("CONTACTO") && (
                    <TableCell>{sale.sale_company?.company_contact}</TableCell>
                )}
                <TableCell>{sale.sale_seller?.seller_name}</TableCell>
                {hideColumns("DESCRIPCIÓN") && <TableCell>{sale.sale_description}</TableCell>}
                <TableCell className={classes.noWrap}>
                    {sale.sale_state}
                    {diferenceState() && (
                        <Tooltip title="El estado no concuerda con el monto de los pagos">
                            <IconButton size="small">
                                <Error />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell>{sale.sale_type_money}</TableCell>
                <TableCell className={classes.noWrap}>
                    {"R$ " + formatedMoney(sale.sale_total || 0)}
                </TableCell>
                {hideColumns("OBSERVACIÓN") && <TableCell>{sale.sale_observation}</TableCell>}
                <TableCell align="center" className={classes.noWrap}>
                    <Tooltip title="Editar">
                        <IconButton size="small" onClick={() => selectAndOpen(sale)}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>

            <TableRow style={{ backgroundColor: obtainStateColor() }}>
                <TableCell padding="none" colSpan={17}>
                    <Collapse align="center" in={findSaleOpened(sale)} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{ display: "flex", justifyContent: "center" }}>
                            <TablePayments sale={sale} />
                            {/* <TableDetractions sale={sale} /> */}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default TableSalesBodyRow;
