import { ActionTypes } from "../types/action.types";

const initialState = {
    sales: [],
    salesOpened: [],
    error: false,
    loading: false,
    selectedSale: null,
    alertMsg: "",
    scrollTable: 0,
};
export const saleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LISTING_SALES:
        case ActionTypes.ADD_SALE:
        case ActionTypes.EDITING_SALE:
            return { ...state, loading: action.payload };

        case ActionTypes.LIST_SALES:
            return {
                ...state,
                loading: false,
                error: false,
                sales: action.payload,
                salesOpened: action.payload.map((e) => ({ _id: e._id, open: false })),
            };

        case ActionTypes.ADD_SALE_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: [action.payload, ...state.sales],
                salesOpened: [...state.salesOpened, { _id: action.payload._id, open: false }],
                alertMsg: "SE AGREGÓ LA VENTA CORRECTAMENTE",
            };

        case ActionTypes.SELECTING_SALE:
            return { ...state, selectedSale: action.payload };

        case ActionTypes.OPENCLOSE_SALE_LIST:
            return {
                ...state,
                salesOpened: state.salesOpened.map((e) =>
                    e._id === action.payload._id ? { ...e, open: !e.open } : e
                ),
            };

        case ActionTypes.EDIT_SALE_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: state.sales.map((element) =>
                    element._id === action.payload._id ? action.payload : element
                ),
                alertMsg: "SE EDITÓ VENTA CORRECTAMENTE",
            };

        case ActionTypes.LIST_SALES_ERROR:
        case ActionTypes.ADD_SALE_ERROR:
        case ActionTypes.EDIT_SALE_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        case ActionTypes.ADD_PAYMENT_OF_SALE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: state.sales.map((element) =>
                    element._id === action.payload.modifySale._id
                        ? {
                              ...element,
                              sale_state: action.payload.modifySale.sale_state,
                              sale_total_paid: action.payload.modifySale.sale_total_paid,
                              sale_total_diference: action.payload.modifySale.sale_total_diference,
                              sale_payments: [...element.sale_payments, action.payload.resPayment],
                          }
                        : element
                ),
                alertMsg: "SE AGREGÓ PAGO CORRECTAMENTE",
            };

        case ActionTypes.EDIT_PAYMENT_OF_SALE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: state.sales.map((sale) =>
                    sale._id === action.payload.modifySale._id
                        ? {
                              ...sale,
                              sale_state: action.payload.modifySale.sale_state,
                              sale_total_paid: action.payload.modifySale.sale_total_paid,
                              sale_total_diference: action.payload.modifySale.sale_total_diference,
                              sale_payments: sale.sale_payments.map((pay) =>
                                  pay._id === action.payload.resPayment._id
                                      ? action.payload.resPayment
                                      : pay
                              ),
                          }
                        : sale
                ),

                alertMsg: "SE EDITÓ EL PAGO CORRECTAMENTE",
            };

        /* DETRACCIONES */
        case ActionTypes.ADD_DETRACTION_OF_SALE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: state.sales.map((element) =>
                    element._id === action.payload.detraction_sale
                        ? {
                              ...element,
                              sale_detractions: [...element.sale_detractions, action.payload],
                          }
                        : element
                ),
                alertMsg: "SE AGREGÓ DETRACCIÓN CORRECTAMENTE",
            };

        case ActionTypes.EDIT_DETRACTION_OF_SALE:
            return {
                ...state,
                loading: false,
                error: false,
                sales: state.sales.map((sale) =>
                    sale._id === action.payload.detraction_sale
                        ? {
                              ...sale,
                              sale_detractions: sale.sale_detractions.map((det) =>
                                  det._id === action.payload._id ? action.payload : det
                              ),
                          }
                        : sale
                ),

                alertMsg: "SE EDITÓ EL PAGO CORRECTAMENTE",
            };

        case ActionTypes.SCROLL_TABLE_SALE:
            return {
                ...state,
                scrollTable: action.payload,
            };

        default:
            return state;
    }
};
