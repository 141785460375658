import { axiosNoToken, axiosToken } from "./axios";

export const listCompaniesAPI = () => {
    return axiosNoToken.get("companies");
};

export const addCompanyAPI = (company) => {
    return axiosToken.post("companies", company);
};

export const editCompanyAPI = (company) => {
    return axiosToken.put("companies/" + company._id, company);
};
