import { ActionTypes } from "../types/action.types";
import { getDollarRate } from "../../axios/sunatAPI";
import { date } from "../../utils/functions";

//LISTADO DE EMPRESAS
const getDollar = (dollar) => ({
    type: ActionTypes.DOLLAR_RATE,
    payload: dollar,
});

export const rateDollarAction = () => {
    return async (dispatch) => {
        try {
            const item = window.localStorage.getItem("dollar");

            if (item) {
                const objitem = JSON.parse(item);

                if (objitem.fecha === date) {
                    dispatch(getDollar(objitem));
                } else {
                    const res = await getDollarRate();
                    window.localStorage.setItem("dollar", JSON.stringify(res.data));
                    dispatch(getDollar(res.data));
                }
            } else {
                const res = await getDollarRate();
                window.localStorage.setItem("dollar", JSON.stringify(res.data));
                dispatch(getDollar(res.data));
            }
        } catch (error) {
            console.log(error);
        }
    };
};
