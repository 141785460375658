import { ActionTypes } from "../types/action.types";

const initialState = {
    banks: [],
    error: false,
    loading: false,
    selectedBank: null,
    alertMsg: "",
};
export const bankReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LISTING_BANKS:
            return { ...state, loading: action.payload };

        case ActionTypes.LIST_BANKS:
            return {
                ...state,
                loading: false,
                error: false,
                banks: action.payload,
            };

        case ActionTypes.SELECTING_BANK:
            return { ...state, selectedBank: action.payload };

        case ActionTypes.LIST_BANKS_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        default:
            return state;
    }
};
