import React, { useState } from "react";

import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Tooltip,
    IconButton,
    Menu,
    ListItemText,
    Badge,
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import useStyles from "../../styles/Styles";

import { Autocomplete } from "@material-ui/lab";

//utils
import { statePayments } from "../../utils/arrays";

const MenuListFilter = ({ filters, setFilters, sellers, years_ini, years_end }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setFilters((prev) => ({ ...prev, [name]: checked }));
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const MenuProps = {
        getContentAnchorEl: null,
        PaperProps: {
            style: {
                maxHeight: 250,
            },
        },
    };

    const optionsChecked = Object.values(filters).filter((e) => e === true).length;

    return (
        <>
            <Tooltip title="Mostrar / Ocultar Columnas">
                <IconButton
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <Badge color="primary" badgeContent={optionsChecked}>
                        <FilterListIcon color="primary" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="opt_date_ini"
                                color="primary"
                                checked={filters.opt_date_ini}
                                onChange={handleCheck}
                            />
                        }
                    />
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel>Fecha Inicio</InputLabel>
                        <Select
                            name="select_date_ini"
                            value={filters.select_date_ini}
                            disabled={!filters.opt_date_ini}
                            onChange={(e) => {
                                setFilters((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.value,
                                }));
                            }}
                        >
                            {years_ini.map((e) => (
                                <MenuItem key={e} value={e}>
                                    <ListItemText primary={e} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MenuItem>

                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="opt_date_end"
                                color="primary"
                                checked={filters.opt_date_end}
                                onChange={handleCheck}
                            />
                        }
                    />
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel>Fecha Vencimiento</InputLabel>
                        <Select
                            name="select_date_end"
                            value={filters.select_date_end}
                            disabled={!filters.opt_date_end}
                            onChange={(e) => {
                                setFilters((prevState) => ({
                                    ...prevState,
                                    [e.target.name]: e.target.value,
                                }));
                            }}
                        >
                            {years_end.map((e) => (
                                <MenuItem key={e} value={e}>
                                    <ListItemText primary={e} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MenuItem>

                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="opt_seller"
                                color="primary"
                                checked={filters.opt_seller}
                                onChange={handleCheck}
                            />
                        }
                    />
                    <Autocomplete
                        options={sellers}
                        getOptionLabel={(option) =>
                            option.seller_name + " " + option.seller_lastname
                        }
                        value={filters.select_seller}
                        getOptionSelected={(option, value) => option._id === value._id}
                        disabled={!filters.opt_seller}
                        onChange={(_, option) => {
                            setFilters((prev) => ({
                                ...prev,
                                select_seller: option,
                            }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                label="Vendedores"
                                variant="outlined"
                                margin="dense"
                            />
                        )}
                        classes={{ option: classes.menu }}
                        fullWidth
                    />
                </MenuItem>

                <MenuItem>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="opt_state"
                                color="primary"
                                checked={filters.opt_state}
                                onChange={handleCheck}
                            />
                        }
                    />
                    <FormControl variant="outlined" margin="dense" fullWidth>
                        <InputLabel>Estado</InputLabel>
                        <Select
                            multiple
                            name="select_state"
                            value={filters.select_state}
                            disabled={!filters.opt_state}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                            style={{ width: "220px" }}
                        >
                            {statePayments.map((pay) => (
                                <MenuItem key={pay} value={pay}>
                                    <ListItemText primary={pay} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </MenuItem>
            </Menu>
        </>
    );
};

export default MenuListFilter;
