import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";

//styles and materials
import useStyles from "../../../styles/Styles";
import { Button, InputAdornment, Grid, Typography, Box } from "@material-ui/core";

import { addPaymentBrAction, editPaymentBrAction } from "../../../redux/actions/salebr.action";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";

import CustomField from "../../customComponents/CustomField";

const AddEditPayment = ({ typeSubmit = "add" }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const addPaymentDispatch = (data) => dispatch(addPaymentBrAction(data));
    const editPaymentDispatch = (data) => dispatch(editPaymentBrAction(data));

    const banks = useSelector((state) => state.banks.banks);

    const selectedSale = useSelector((state) => state.sales.selectedSale);
    const selectedPayment = useSelector((state) => state.payments.selectedPayment);

    // const loadBanks = useSelector((state) => state.banks.loading);

    const entireDate = (param) => {
        return param.toString().length < 2 ? `0${param}` : param;
    };
    const today = new Date();
    const year = today.getFullYear();
    const month = entireDate(today.getMonth() + 1);
    const day = entireDate(today.getDate());
    const date = `${year}-${month}-${day}`;

    const [payment, setPayment] = useState(
        typeSubmit === "edit"
            ? selectedPayment
            : {
                  payment_amount: 0,
                  payment_number: "",
                  payment_bank: banks[0],
                  payment_date_pay: date,
                  payment_detail: "",
              }
    );

    const handleChange = (event) => {
        const { value, name } = event.target;
        setPayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const formatedMoney = (value) =>
        value.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

    const handleChangeMoney = (event) => {
        const { value, name } = event.target;
        const valueFormated = value.replace(/^\D+/g, "").replace(/,/g, "");

        setPayment((prevState) => ({
            ...prevState,
            [name]: valueFormated,
        }));
    };

    const handleBlur = (event) => {
        const { value, name } = event.target;
        const valueFormated = value.replace(/^\D+/g, "").replace(/,/g, "");

        setPayment((prevState) => ({
            ...prevState,
            [name]: parseFloat(valueFormated),
        }));
    };

    const submitPayment = (event) => {
        event.preventDefault();

        let dataOut = payment;
        typeSubmit === "add" && (dataOut.payment_sale = selectedSale._id);

        dataOut.payment_amount === null && (dataOut.payment_amount = 0);
        dataOut.payment_amount = formatedMoney(payment.payment_amount).replace(/,/g, "");

        typeSubmit === "add" ? addPaymentDispatch(dataOut) : editPaymentDispatch(dataOut);

        history.push("/salesbr");
    };

    if (typeSubmit === "add" && selectedSale === null) {
        return <Redirect to={"/salesbr"} />;
    }

    if (typeSubmit === "edit" && selectedPayment === null) {
        return <Redirect to={"/salesbr"} />;
    }

    return (
        <form onSubmit={submitPayment} className={classes.form}>
            <Box mb={4} borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR PAGO
                    </Typography>
                </Box>

                <Box pb={4} m={4}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                autoFocus
                                label="Fecha de Pago"
                                type="date"
                                name="payment_date_pay"
                                value={payment.payment_date_pay}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="N° de Pago"
                                type="text"
                                name="payment_number"
                                value={payment.payment_number}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <Autocomplete
                                options={banks}
                                getOptionLabel={(option) =>
                                    option.bank_name + " " + (option.bank_number || "")
                                }
                                value={payment.payment_bank}
                                getOptionSelected={(option, value) => option._id === value._id}
                                onChange={(_, option) => {
                                    setPayment((prev) => ({
                                        ...prev,
                                        payment_bank: option,
                                    }));
                                }}
                                renderInput={(params) => <CustomField {...params} label="Banco" />}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Detalle"
                                type="text"
                                name="payment_detail"
                                value={payment.payment_detail}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} xl={3}>
                            <CustomField
                                label="Monto"
                                type="text"
                                name="payment_amount"
                                value={formatedMoney(payment.payment_amount)}
                                onChange={handleChangeMoney}
                                onBlur={handleBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{"R$"}</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" onClick={() => history.push("/salesbr")}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditPayment;
