import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

//styles and materials
import { Table, TableContainer, TableBody, Paper, TablePagination, Box } from "@material-ui/core";
import useStyles from "../../styles/Styles";

//redux
import { useDispatch } from "react-redux";
import { selectServiceAction } from "../../redux/actions/service.action";

//componentes
import TableServicesHeader from "./TableServicesHeader";
import TableServicesBodyRow from "./TableServicesBodyRow";

//functions
import { obtainOpen } from "../../utils/functions";

const TableSales = ({ services }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectServiceDispatch = (data) => dispatch(selectServiceAction(data));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const selectAndOpen = (data, type) => {
        selectServiceDispatch(data);
        history.push(`/estructures/list`);
    };

    const spaceContent = clsx(
        classes.heightTableSale,
        obtainOpen() ? classes.widthOpenTableSale : classes.widthTableSale
    );

    return (
        <Box>
            <TableContainer className={spaceContent} component={Paper}>
                <Table size="small">
                    <TableServicesHeader />

                    <TableBody>
                        {(rowsPerPage > 0
                            ? services.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : services
                        ).map((service) => (
                            <TableServicesBodyRow
                                key={service._id}
                                service={service}
                                selectAndOpen={selectAndOpen}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                style={{ display: "flex" }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={services.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Hojas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                backIconButtonText="Anterior"
                nextIconButtonText="Siguiente"
            />
        </Box>
    );
};

export default TableSales;
