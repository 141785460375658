import { lighttheme, darktheme } from "../theme/Theme";

export const verifyTheme = () => {
    let tema = localStorage.getItem("theme");
    if (!tema) {
        localStorage.setItem("theme", "light");
        return lighttheme;
    }
    if (tema === "dark") return darktheme;
    return lighttheme;
};
