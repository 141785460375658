import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";

import { Link } from "react-router-dom";

import useStyles from "../../styles/Styles";

import TableEstructures from "./TableServices";

import { useSelector, useDispatch } from "react-redux";
import { listCompaniesAction } from "../../redux/actions/company.action";
// import { listEstructuresAction } from "../../redux/actions/estructure.action";
import { listServicesAction } from "../../redux/actions/service.action";
import { rateDollarAction } from "../../redux/actions/sunat.action";

const Estructure = () => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const listCompaniesDispatch = () => dispatch(listCompaniesAction());
    const listServicesDispatch = () => dispatch(listServicesAction());
    // const listEstructuresDispatch = () => dispatch(listEstructuresAction());
    const rateDollarDispatch = () => dispatch(rateDollarAction());

    const services = useSelector((state) => state.services.services); //TRAE ARRAY ESTRUCTURAS
    // const estructures = useSelector((state) => state.estructures.estructures); //TRAE ARRAY ESTRUCTURAS
    const companies = useSelector((state) => state.companies.companies);
    const rateDollar = useSelector((state) => state.sunat.dollarRate);

    const [filters, setFilters] = useState({
        input_search: "",
    });

    const filterSales = (rows) => {
        return rows.filter(
            (row) =>
                row.ser_company?.company_name
                    .toLowerCase()
                    .indexOf(filters.input_search.toLowerCase()) > -1
        );
    };

    useEffect(
        () => {
            !services.length && listServicesDispatch();
            // !estructures.length && listEstructuresDispatch();
            !companies.length && listCompaniesDispatch();
            !rateDollar.length && rateDollarDispatch();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Box>
            <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={5} sm={3} lg={2} xl={2}>
                        <Button
                            fullWidth
                            size="small"
                            className={classes.fullHeight}
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={"/estructures/add"}
                        >
                            Agregar Servicio
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} lg={3} xl={2}>
                        <TextField
                            fullWidth
                            size="small"
                            className={classes.button}
                            name="input_search"
                            label="Buscador"
                            variant="outlined"
                            onChange={(e) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    [e.target.name]: e.target.value,
                                }))
                            }
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box>
                <TableEstructures
                    // estructures={filterSales(estructures)}
                    services={filterSales(services)}
                />
            </Box>
        </Box>
    );
};

export default Estructure;
