import React from "react";
import { useHistory } from "react-router-dom";

//styles and materials
import { Table, Paper, TableContainer } from "@material-ui/core";

//redux
import { useDispatch } from "react-redux";
import { selectDetracctionAction } from "../../../redux/actions/detraction.action";
import { selectSaleAction } from "../../../redux/actions/sale.action";

//componentes
import TableDetractionsHeader from "./TableDetractionsHeader";
import TableDetractionsBodyRows from "./TableDetractionsBodyRows";

const TableDetractions = ({ sale }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const selectDetractionDispatch = (data) => dispatch(selectDetracctionAction(data));
    const selectSaleDispatch = (data) => dispatch(selectSaleAction(data));

    const selectAndOpen = (data, type) => {
        type === "add" && selectSaleDispatch(data) && history.push("/sales/detraction/add");
        type === "edit" && selectDetractionDispatch(data) && history.push("/sales/detraction/edit");
    };

    return (
        <>
            <TableContainer style={{ width: "40%" }} component={Paper}>
                <Table size="small">
                    <TableDetractionsHeader sale={sale} selectAndOpen={selectAndOpen} />
                    <TableDetractionsBodyRows sale={sale} selectAndOpen={selectAndOpen} />
                </Table>
            </TableContainer>
        </>
    );
};

export default TableDetractions;
