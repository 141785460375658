import { ActionTypes } from "../types/action.types";
import { login, verify } from "../../axios/authAPI";

const addAuth = () => ({
    type: ActionTypes.SET_AUTH,
});

const addAuthDone = (auth) => ({
    type: ActionTypes.SET_AUTH_DONE,
    payload: auth,
});

const addAuthError = (error) => ({
    type: ActionTypes.SET_AUTH_ERROR,
    payload: error,
});

const deleteAuth = () => ({
    type: ActionTypes.SET_AUTH_DELETE,
});

export const addAuthAction = (user) => {
    return async (dispatch) => {
        dispatch(addAuth());

        try {
            const res = await login(user);
            dispatch(addAuthDone({ success: res.data.success, user: res.data.user }));
            localStorage.setItem("token", res.data.token);
        } catch (error) {
            dispatch(addAuthError(error.response.data.error));
        }
    };
};

export const findAuthAction = (user) => {
    return async (dispatch) => {
        dispatch(addAuth());
        try {
            if (localStorage.getItem("token") !== null) {
                const res = await verify();
                dispatch(addAuthDone(res.data));
            } else {
                dispatch(addAuthDone({ success: false }));
            }
        } catch (error) {
            console.log(error);
            dispatch(addAuthDone({ success: false }));
            dispatch(addAuthError(true));
        }
    };
};

export const deleteAuthAction = () => {
    return (dispatch) => {
        dispatch(addAuth());
        try {
            dispatch(deleteAuth());
        } catch (error) {
            console.log(error);
            dispatch(addAuthError(true));
        }
    };
};
