import { ActionTypes } from "../types/action.types";
import { addSaleAPI, listSalesAPI, editSaleAPI } from "../../axios/saleAPI";

//LISTADO DE VENTAS
const listingSales = () => ({
    type: ActionTypes.LISTING_SALES,
    payload: true,
});

const listSales = (sales) => ({
    type: ActionTypes.LIST_SALES,
    payload: sales,
});

const listSalesError = (error) => ({
    type: ActionTypes.LIST_SALES_ERROR,
    payload: error,
});

export const listSalesAction = () => {
    return async (dispatch) => {
        dispatch(listingSales());

        try {
            const res = await listSalesAPI();

            res.data.sort((a, b) => {
                let fa = a.sale_num_serie.toLowerCase(),
                    fb = b.sale_num_serie.toLowerCase();

                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            });
            dispatch(listSales(res.data));
        } catch (error) {
            dispatch(listSalesError(error.response.data.error));
        }
    };
};

//AGREGADO DE VENTA
const addSale = () => ({
    type: ActionTypes.ADD_SALE,
    payload: true,
});

const addSaleDone = (sale) => ({
    type: ActionTypes.ADD_SALE_DONE,
    payload: sale,
});

const addSaleError = (error) => ({
    type: ActionTypes.ADD_SALE_ERROR,
    payload: error,
});

export const addSaleAction = (sale) => {
    return async (dispatch) => {
        dispatch(addSale());

        try {
            const res = await addSaleAPI(sale);

            //si todo sale bien actualizar el state
            dispatch(addSaleDone(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(addSaleError(error.response.data.error));
        }
    };
};

//SELECCIÓN DE VENTA
const selectingSale = (sale) => ({
    type: ActionTypes.SELECTING_SALE,
    payload: sale,
});

export const selectSaleAction = (sale) => {
    return async (dispatch) => {
        dispatch(selectingSale(sale));
    };
};

//EDITAR VENTA
const editingSale = () => ({
    type: ActionTypes.EDITING_SALE,
    payload: true,
});

const editSale = (params) => ({
    type: ActionTypes.EDIT_SALE_DONE,
    payload: params,
});

const editSaleError = (error) => ({
    type: ActionTypes.EDIT_SALE_ERROR,
    payload: error,
});

export const editSaleAction = (sale) => {
    return async (dispatch) => {
        dispatch(editingSale());

        try {
            const res = await editSaleAPI(sale);

            //si todo sale bien actualizar el state
            dispatch(editSale(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editSaleError(error.response.data.error));
        }
    };
};

const changeStateOpeRow = (row) => ({
    type: ActionTypes.OPENCLOSE_SALE_LIST,
    payload: row,
});

export const openCloseRowAction = (row) => {
    return (dispatch) => {
        dispatch(changeStateOpeRow(row));
    };
};

const changeHeightTable = (height) => ({
    type: ActionTypes.SCROLL_TABLE_SALE,
    payload: height,
});

export const tableScrollAction = (row) => {
    return (dispatch) => {
        dispatch(changeHeightTable(row));
    };
};
