import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../components/homepage/Home";
import Company from "../components/companies/Company";
import Seller from "../components/sellers/Seller";

import Sale from "../components/sales/Sale";
import AddEditSale from "../components/sales/AddEditSale";

import AddEditPaymet from "../components/sales/payments/AddEditPaymet";
import AddEditDetraction from "../components/sales/detractions/AddEditDetraction";

import SaleBR from "../components/salesbr/Sale";
import AddEditSaleBR from "../components/salesbr/AddEditSale";
import AddEditPaymetBR from "../components/salesbr/payments/AddEditPaymet";

import Services from "../components/estructure/Services";
import AddService from "../components/estructure/AddService";
import Estructures from "../components/estructure/Estructures";

const RouterComponents = () => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/companies" component={Company} />
            <Route exact path="/sellers" component={Seller} />

            <Route exact path="/sales" component={Sale} />
            <Route exact path="/sales/add" component={AddEditSale} />
            <Route exact path="/sales/edit">
                <AddEditSale typeSubmit="edit" />
            </Route>

            <Route exact path="/sales/payment/add" component={AddEditPaymet} />
            <Route exact path="/sales/payment/edit">
                <AddEditPaymet typeSubmit="edit" />
            </Route>

            <Route exact path="/sales/detraction/add" component={AddEditDetraction} />
            <Route exact path="/sales/detraction/edit">
                <AddEditDetraction typeSubmit="edit" />
            </Route>

            {/* SALES BRASIL */}

            <Route exact path="/salesbr" component={SaleBR} />
            <Route exact path="/salesbr/add" component={AddEditSaleBR} />
            <Route exact path="/salesbr/edit">
                <AddEditSaleBR typeSubmit="edit" />
            </Route>
            <Route exact path="/salesbr/payment/add" component={AddEditPaymetBR} />
            <Route exact path="/salesbr/payment/edit">
                <AddEditPaymetBR typeSubmit="edit" />
            </Route>

            <Route exact path="/estructures" component={Services} />
            <Route exact path="/estructures/add" component={AddService} />
            {/* <Route exact path="/estructures/add" component={Estructures} /> */}
            {/* <Route exact path="/estructures/show">
                <Estructures typeSubmit="show" />
            </Route> */}
            <Route exact path="/estructures/list" component={Estructures} />
        </Switch>
    );
};

export default RouterComponents;
