import { axiosNoToken, axiosToken } from "./axios";

export const listSalesAPI = () => {
    return axiosNoToken.get("sales");
};

export const listSalesBrAPI = () => {
    return axiosNoToken.get("sales/br");
};

export const addSaleAPI = (sale) => {
    return axiosToken.post("sales", sale);
};

export const editSaleAPI = (sale) => {
    return axiosToken.put("sales/" + sale._id, sale);
};
