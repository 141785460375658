import { ActionTypes } from "../types/action.types";
import { addDetractionAPI, editDetractionAPI } from "../../axios/detractionAPI";

//AGREGADO DE DETRACCIÓN
const addSale = () => ({
    type: ActionTypes.ADD_SALE,
    payload: true,
});

//DENTRO DEL REDUCER DE SALE
const addDetracctionToSaleDone = (det) => ({
    type: ActionTypes.ADD_DETRACTION_OF_SALE,
    payload: det,
});

const addSaleError = (error) => ({
    type: ActionTypes.ADD_SALE_ERROR,
    payload: error,
});

export const addDetracctionAction = (det) => {
    return async (dispatch) => {
        dispatch(addSale());

        try {
            const res = await addDetractionAPI(det);

            dispatch(addDetracctionToSaleDone(res.data));
        } catch (error) {
            dispatch(addSaleError(error));
            // console.log(error);
        }
    };
};

//SELECCIÓN DE VENTA
const selectingDetracction = (det) => ({
    type: ActionTypes.SELECTING_DETRACTION,
    payload: det,
});

export const selectDetracctionAction = (det) => {
    return async (dispatch) => {
        dispatch(selectingDetracction(det));
    };
};

//
//
//EDITAR PAGO
const editingDetracction = () => ({
    type: ActionTypes.EDITING_SALE,
    payload: true,
});

const editDetracction = (det) => ({
    type: ActionTypes.EDIT_DETRACTION_OF_SALE,
    payload: det,
});

const editDetracctionError = (error) => ({
    type: ActionTypes.EDIT_SALE_ERROR,
    payload: error,
});

export const editDetracctionAction = (det) => {
    return async (dispatch) => {
        dispatch(editingDetracction());

        try {
            const res = await editDetractionAPI(det);

            dispatch(editDetracction(res.data));
        } catch (error) {
            dispatch(editDetracctionError(error));
        }
    };
};
