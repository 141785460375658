const entireDate = (param) => {
    return param.toString().length < 2 ? `0${param}` : param;
};

const today = new Date();
const year = today.getFullYear();
const month = entireDate(today.getMonth() + 1);
const day = entireDate(today.getDate());
export const date = `${year}-${month}-${day}`;

export const localeDate = (date) => {
    // const locale = new Date(date);
    // const y = locale.getFullYear();
    // const m = entireDate(locale.getMonth() + 1);
    // const d = entireDate(locale.getDate());
    const d = date.split("-")[2];
    const m = date.split("-")[1];
    const y = date.split("-")[0];
    return `${d}/${m}/${y}`;
    //new Date(date).toLocaleDateString(); //.replace(/\//g, "-");
};

//ayuda a poner comas y PONER DOS DECIMALES
export const formatedMoney = (value) =>
    value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

//remueve las comas y letras
export const removeChars = (value) => {
    //replace(/^\D+/g, "")
    return value.replace(/[^\d.-]/g, "").replace(/,/g, "");
};

export const obtainOpen = (initialValue = false) => {
    const item = window.localStorage.getItem("open");
    return item ? JSON.parse(item) : initialValue;
};

export const fixDecimal = (value) => parseFloat(value.toFixed(2));

// export { date, localeDate, formatedMoney, removeChars, obtainOpen };
