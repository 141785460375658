import { axiosNoToken, axiosToken } from "./axios";

export const listEstructuresAPI = () => {
    return axiosNoToken.get("estructures");
};

export const addEstructureAPI = (estructure) => {
    return axiosToken.post("estructures", estructure);
};

export const editEstructureAPI = (estructure) => {
    return axiosToken.put("estructures/" + estructure._id, estructure);
};
