import React from "react";

import { Edit } from "@material-ui/icons";

//styles and materials
import { TableCell, TableRow, Tooltip, IconButton, TableBody } from "@material-ui/core";
import { formatedMoney, localeDate } from "../../..//utils/functions";

const TablePaymentsBodyRow = ({ sale, selectAndOpen }) => {
    return (
        <TableBody>
            {sale.sale_payments.map((data) => (
                <TableRow key={data?._id}>
                    <TableCell>{localeDate(data?.payment_date_pay)}</TableCell>
                    <TableCell>{data?.payment_number}</TableCell>
                    <TableCell align="center">
                        {data?.payment_bank?.bank_name + " " + data?.payment_bank?.bank_number}
                    </TableCell>
                    <TableCell>{data?.payment_detail}</TableCell>
                    <TableCell align="right">{formatedMoney(data?.payment_amount || 0)}</TableCell>
                    <TableCell align="center">
                        <Tooltip title="Editar">
                            <IconButton
                                size="small"
                                onClick={() => selectAndOpen(sale, data, "edit")}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            ))}

            {/* EL TOTAL DE LOS PAGOS  style={{ wordSpacing: "15px" }} */}
            <TableRow>
                <TableCell align="right" colSpan={4}>
                    <b>Total: </b>
                </TableCell>
                <TableCell align="right">
                    <b>{formatedMoney(sale.sale_total_paid || 0)}</b>
                </TableCell>
                <TableCell />
            </TableRow>

            {/* LA DIFERENCIA RESPECTO AL TOTAL */}
            <TableRow>
                <TableCell align="right" colSpan={4}>
                    <b>Diferencia: </b>
                </TableCell>
                <TableCell align="right">
                    <b>{formatedMoney(sale.sale_total_diference || 0)}</b>
                </TableCell>
                <TableCell />
            </TableRow>
        </TableBody>
    );
};

export default TablePaymentsBodyRow;
