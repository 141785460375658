import { ActionTypes } from "../types/action.types";

const initialState = {
    companies: [],
    error: false,
    loading: false,
    selectedCompany: null,
    alertMsg: "",
};
export const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LISTING_COMPANIES:
        case ActionTypes.ADD_COMPANY:
        case ActionTypes.EDITING_COMPANY:
            return { ...state, loading: action.payload };

        case ActionTypes.LIST_COMPANIES:
            return {
                ...state,
                loading: false,
                error: false,
                companies: action.payload,
            };

        case ActionTypes.ADD_COMPANY_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                companies: [...state.companies, action.payload],
                alertMsg: "SE AGREGÓ EMPRESA CORRECTAMENTE",
            };

        case ActionTypes.SELECTING_COMPANY:
            return { ...state, selectedCompany: action.payload };

        case ActionTypes.EDIT_COMPANY_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                companies: state.companies.map((element) =>
                    element._id === action.payload._id
                        ? (element = action.payload)
                        : element
                ),
                alertMsg: "SE EDITÓ EMPRESA CORRECTAMENTE",
            };

        case ActionTypes.LIST_COMPANIES_ERROR:
        case ActionTypes.ADD_COMPANY_ERROR:
        case ActionTypes.EDIT_COMPANY_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        default:
            return state;
    }
};
