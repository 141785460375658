import { ActionTypes } from "../types/action.types";

const initialState = {
    auth: null,
    loading: false,
    error: false,
    alertMsg: "",
};
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_AUTH:
            return { ...state, loading: true, error: false };

        //COMIENZA CON UN OBJECT PARA EL USO DE LA APP
        case ActionTypes.SET_AUTH_DONE:
            return {
                ...state,
                loading: false,
                auth: action.payload,
                alertMsg: "",
            };

        //DEVUELVE EL ERROR TRUE Y COMO PAYLOAD EL ERROR PARA CARGARLO EN UN ALERT
        case ActionTypes.SET_AUTH_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        //CAMBIA EL AUTH A NOT AUTHENTICATED Y MUESTRA EL LOGIN
        case ActionTypes.SET_AUTH_DELETE:
            return {
                ...state,
                loading: false,
                auth: { success: false },
            };

        //AGREGAMOS LA ALERTA

        default:
            return state;
    }
};
