import React from // , { useEffect }
"react";
import { HashRouter as Router } from "react-router-dom";
import clsx from "clsx";
import { useLocalStorage } from "../hooks/useLocalStorage";
import CssBaseline from "@material-ui/core/CssBaseline";

//redux
// import { useDispatch } from "react-redux";
// import { listCompaniesAction } from "../redux/actions/company.action";
// import { listSellersAction } from "../redux/actions/seller.action";
// import { listSalesAction } from "../redux/actions/sale.action";
// import { listBanksAction } from "../redux/actions/bank.action";
// import { listEstructuresAction } from "../redux/actions/estructure.action";
// import { rateDollarAction } from "../redux/actions/sunat.action";

//styles and material
import useStyles from "../styles/navStyles";
import { Box, Hidden } from "@material-ui/core";

//Componentes
import RoutesComponents from "../routes/RoutesComponents";
import AppBar from "./nav/AppBar";
import Drawer from "./nav/Drawer";

const App = () => {
    //inicar estilos
    const classes = useStyles();

    //uso del dispatch de redux para las acciones
    // const dispatch = useDispatch();

    // const listCompaniesDispatch = () => dispatch(listCompaniesAction());
    // const listSellersDispatch = () => dispatch(listSellersAction());
    // const listSalesDispatch = () => dispatch(listSalesAction());
    // const listBanksDispatch = () => dispatch(listBanksAction());
    // const listEstructuresDispatch = () => dispatch(listEstructuresAction());
    // const rateDollarDispatch = () => dispatch(rateDollarAction());

    //declarar open y su función para el nav
    const [open, setOpen] = useLocalStorage("open", true);
    const cambiarOpen = () => {
        setOpen(!open);
    };

    //métodos de abreviación
    //espacio de ajuste de espacio para el contenedor de componentes
    const spaceContent = clsx(classes.content, open && classes.contentShift);

    //verifica si hay authenticación
    /*   useEffect(
        () => {
            // listCompaniesDispatch();
            // listSellersDispatch();
            // listSalesDispatch();
            // listBanksDispatch();
            // listEstructuresDispatch();
            // rateDollarDispatch();
        },
        // eslint-disable-next-line
        []
    ); */

    return (
        <Box display="flex">
            <Router>
                <CssBaseline />

                <AppBar open={open} cambiarOpen={cambiarOpen} />

                <Hidden xsDown>
                    <Drawer open={open} cambiarOpen={cambiarOpen} variant="persistent" />
                </Hidden>
                <Hidden smUp>
                    <Drawer
                        open={open}
                        cambiarOpen={cambiarOpen}
                        onClose={cambiarOpen}
                        variant="temporary"
                    />
                </Hidden>
                <main className={spaceContent}>
                    <div className={classes.drawerHeader}></div>
                    <RoutesComponents />
                </main>
            </Router>
        </Box>
    );
};
export default App;
