import { createMuiTheme } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import * as colors from "@material-ui/core/colors";

export const lighttheme = createMuiTheme({
    typography: {
        fontSize: 12,
    },
    palette: {
        type: "light",
        primary: {
            main: grey[900],
        },
        secondary: {
            main: colors.amber[700],
        },
        success: {
            main: colors.green[500],
        },
    },

    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: colors.grey[900],
                color: colors.grey[100],
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: colors.grey[900],
                color: colors.grey[100],
                "& 	.MuiListItem-root": {
                    borderRadius: "5px",
                    width: "85%",
                    margin: "0 15px",
                    // padding: "0 0 0 20px",
                    // paddinRight: "20px",
                    display: "flex",
                },

                "& .MuiListItem-root:hover": {
                    "& .MuiListItemIcon-root, .MuiListItemText-root, .MuiSvgIcon-root": {
                        color: colors.amber[700],
                    },
                },

                "& .MuiListItemIcon-root": {
                    minWidth: "30px",
                    color: "inherit",
                },

                "& .MuiDivider-root": {
                    backgroundColor: "currentColor",
                    opacity: 0.3,
                },
                "& .MuiIconButton-root": {
                    color: "inherit",
                },
            },
        },

        MuiTableHead: {
            root: {
                backgroundColor: colors.grey[900],
                "& .MuiTableCell-root": {
                    color: colors.grey[100],
                },
            },
        },

        MuiTableBody: {
            root: {
                "& .MuiTableCell-root": {
                    // borderLeft: "1px solid rgba(224, 224, 224, 1)",
                },
            },
        },

        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: colors.grey[50],
                    "& .MuiListItemIcon-root, .MuiListItemText-root, .MuiSvgIcon-root": {
                        color: colors.amber[800],
                        "& .MuiTypography-root": {
                            fontWeight: 700,
                            fontSize: 13.5,
                        },
                    },
                },
                "&$selected:hover": {
                    backgroundColor: colors.grey[50],
                    "& .MuiListItemIcon-root, .MuiListItemText-root, .MuiSvgIcon-root": {
                        color: colors.amber[900],
                        "& .MuiTypography-root": {
                            fontWeight: 700,
                            fontSize: 13.5,
                        },
                    },
                },
            },
        },

        MuiBadge: {
            colorPrimary: {
                color: colors.amber[800],
            },
        },
    },
});

export const darktheme = createMuiTheme({
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#",
                color: "white",
            },
        },
    },
    palette: {
        type: "dark",
        primary: {
            main: grey[50],
        },
        secondary: {
            main: grey[900],
        },
    },
});

// export default theme;
