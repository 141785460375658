import { ActionTypes } from "../types/action.types";

const initialState = {
    services: [],
    servicesOpened: [],
    error: false,
    loading: false,
    selectedService: null,
    alertMsg: "",
    scrollTable: 0,
};
export const serviceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LISTING_SERVICES:
        case ActionTypes.ADD_SERVICE:
        case ActionTypes.EDITING_SERVICE:
        case ActionTypes.EDITING_ESTRUCTURE:
            return { ...state, loading: action.payload };

        case ActionTypes.LIST_SERVICES:
            return {
                ...state,
                loading: false,
                error: false,
                services: action.payload,
                servicesOpened: action.payload.map((e) => ({ _id: e._id, open: false })),
            };

        case ActionTypes.ADD_SERVICE_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                services: [action.payload, ...state.services],
                servicesOpened: [...state.servicesOpened, { _id: action.payload._id, open: false }],
                alertMsg: "SE AGREGÓ LA VENTA CORRECTAMENTE",
            };

        case ActionTypes.SELECTING_SERVICE:
            return { ...state, selectedService: action.payload };

        case ActionTypes.EDIT_SERVICE_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                services: state.services.map((element) =>
                    element._id === action.payload._id ? action.payload : element
                ),
                alertMsg: "SE EDITÓ VENTA CORRECTAMENTE",
            };

        case ActionTypes.EDIT_ESTRUCTURE_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                services: state.services.map(
                    (ser) =>
                        ser._id === state.selectedService._id
                            ? {
                                  ...ser,
                                  ser_estructures: ser?.ser_estructures.map((est) =>
                                      est._id === action.payload._id ? action.payload : est
                                  ),
                              }
                            : ser
                    // console.log(state.selectedService)
                    // console.log("reduce serivce")
                ),
                alertMsg: "SE EDITÓ VENTA CORRECTAMENTE",
            };

        case ActionTypes.LIST_SERVICES_ERROR:
        case ActionTypes.ADD_SERVICE_ERROR:
        case ActionTypes.EDIT_SERVICE_ERROR:
        case ActionTypes.EDIT_ESTRUCTURE_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        default:
            return state;
    }
};
