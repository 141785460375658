import React, { useState } from "react";
import clsx from "clsx";

//styles and materials
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Paper,
    TablePagination,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import useStyles from "../../styles/Styles";

//icons
import { Edit } from "@material-ui/icons";

//redux
import { useDispatch } from "react-redux";
import { selectCompanyAction } from "../../redux/actions/company.action";

//functions
import { obtainOpen } from "../../utils/functions";

const CompanyTable = ({ companies, openCloseAndType }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const selectCompanyDispatch = (data) => dispatch(selectCompanyAction(data));

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //redirección
    const selectAndOpen = (data) => {
        selectCompanyDispatch(data);
        openCloseAndType("edit");
    };

    const spaceContent = clsx(
        classes.heightTableSale,
        obtainOpen() ? classes.widthOpenTableSale : classes.widthTableSale
    );

    return (
        <>
            <TableContainer component={Paper} className={spaceContent}>
                <Table size="small">
                    <TableHead className={classes.stickyHeadTable}>
                        <TableRow>
                            <TableCell align="center">RAZON SOCIAL</TableCell>
                            <TableCell align="center">RUC</TableCell>
                            <TableCell align="center">TELÉFONO</TableCell>
                            <TableCell align="center">CORREO</TableCell>
                            <TableCell align="center">CONTACTO</TableCell>
                            <TableCell align="center">ACCIONES</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(rowsPerPage > 0
                            ? companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : companies
                        ).map((info) => (
                            <TableRow key={info._id}>
                                <TableCell>{info.company_name}</TableCell>
                                <TableCell>{info.company_ruc}</TableCell>
                                <TableCell>{info.company_phone}</TableCell>
                                <TableCell>{info.company_email}</TableCell>
                                <TableCell>{info.company_contact}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="Editar">
                                        <IconButton
                                            size="small"
                                            onClick={() => selectAndOpen(info)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={companies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Hojas por página"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                backIconButtonText="Anterior"
                nextIconButtonText="Siguiente"
            />
        </>
    );
};

export default CompanyTable;
