import React from "react";

import { Grid, Box, Typography } from "@material-ui/core";

import CustomField from "../../customComponents/CustomField";
import { TextField } from "@material-ui/core";

import { formatedMoney } from "../../../utils/functions";

const ImpreviseCostRow = ({ estructure, setEstructure, typeSubmit }) => {
    const handleChange = (event) => {
        const { value, name } = event.target;

        const nameCell =
            name === "est_impre_costs_unfo_percent"
                ? "est_impre_costs_unfo"
                : "est_impre_costs_guarant";

        const costRow =
            estructure.est_type_money === "Dolares"
                ? (estructure.est_total_sale_dolar * value) / 100
                : (estructure.est_total_sale_sol * value) / 100;

        const total =
            costRow +
            (nameCell === "est_impre_costs_unfo"
                ? estructure.est_impre_costs_guarant
                : estructure.est_impre_costs_unfo);

        const abc = estructure.est_direct_costs_total + estructure.est_variable_costs_total + total;

        const resultValue =
            estructure.est_type_money === "Dolares"
                ? estructure.est_total_sale_dolar
                : estructure.est_total_sale_sol;
        const net_value = resultValue / 1.18;

        const financial_provision = ((net_value - abc) * estructure.est_finan_provi_percent) / 100;
        const financial_profit = (net_value * estructure.est_finan_profit_percent) / 100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total =
            resultValue - net_value + (resultValue * estructure.est_tax_export_percent) / 100;

        setEstructure((prevState) => ({
            ...prevState,
            [name]: value,
            [nameCell]: costRow,
            est_impre_cost_total: total,
            est_sum_total_abc: abc,

            est_finan_pre_service: net_value - abc,
            est_finan_provi: financial_provision,
            est_finan_profit: financial_profit,
            est_finan_profit_real: financial_profit_real,

            est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * estructure.est_tax_export_percent) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total,
        }));
    };

    return (
        <Grid container item lg={12} spacing={1}>
            <Grid item lg={12} xl={12}>
                <Box display="flex" ml={4}>
                    <Typography variant="h6">Costos Imprevistos y Seguros (C)</Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Imprevistos" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Porcentaje"
                    name="est_impre_costs_unfo_percent"
                    value={estructure.est_impre_costs_unfo_percent}
                    onChange={handleChange}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_impre_costs_unfo"
                    value={formatedMoney(estructure.est_impre_costs_unfo)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            <Grid item xs={12} sm={6} lg={3} xl={3}>
                <CustomField value="Seguros" disabled />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Porcentaje"
                    name="est_impre_costs_guarant_percent"
                    value={estructure.est_impre_costs_guarant_percent}
                    onChange={handleChange}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    style={{ backgroundColor: "#e7e7e4" }}
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total"
                    type="text"
                    name="est_impre_costs_guarant"
                    value={formatedMoney(estructure.est_impre_costs_guarant)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>

            <Box width="100%" />

            <Grid item lg={5} />
            <Grid container item lg={2} xl={3} justify="center" alignItems="center">
                <Typography variant="subtitle1">TOTAL:</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Imp + Seguros"
                    type="text"
                    name="est_impre_cost_total"
                    value={formatedMoney(estructure.est_impre_cost_total)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
        </Grid>
    );
};

export default ImpreviseCostRow;
