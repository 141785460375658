import { ActionTypes } from "../types/action.types";

const initialState = {
    sellers: [],
    error: false,
    loading: false,
    selectedSeller: null,
    alertMsg: "",
};
export const sellerReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LISTING_SELLERS:
        case ActionTypes.ADD_SELLER:
        case ActionTypes.EDITING_SELLER:
            return { ...state, loading: action.payload };

        case ActionTypes.LIST_SELLERS:
            return {
                ...state,
                loading: false,
                error: false,
                sellers: action.payload,
            };

        case ActionTypes.ADD_SELLER_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                sellers: [...state.sellers, action.payload],
                alertMsg: "SE AGREGÓ EMPRESA CORRECTAMENTE",
            };

        case ActionTypes.SELECTING_SELLER:
            return { ...state, selectedSeller: action.payload };

        case ActionTypes.EDIT_SELLER_DONE:
            return {
                ...state,
                loading: false,
                error: false,
                sellers: state.sellers.map((element) =>
                    element._id === action.payload._id ? (element = action.payload) : element
                ),
                alertMsg: "SE EDITÓ EMPRESA CORRECTAMENTE",
            };

        case ActionTypes.LIST_SELLERS_ERROR:
        case ActionTypes.ADD_SELLER_ERROR:
        case ActionTypes.EDIT_SELLER_ERROR:
            return { ...state, loading: false, error: true, alertMsg: action.payload };

        default:
            return state;
    }
};
