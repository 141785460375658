import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//styles and materials
import useStyles from "../../../styles/Styles";
import { Grid, Button, Box, Typography } from "@material-ui/core";

//redux
import { editEstructureAction } from "../../../redux/actions/service.action";
import { useDispatch } from "react-redux";

//utils

//components
import InformationRow from "./InformationRow";
import DirectCostRow from "./DirectCostRow";
import VariableCostRow from "./VariableCostRow";
import ImpreviseCostRow from "./ImpreviseCostRow";
import FinancialCostRow from "./FinancialCostRow";
import ResultsCostRow from "./ResultsCostRow";
import TaxCostRow from "./TaxCostRow";
import CustomField from "../../customComponents/CustomField";

import { fixDecimal } from "../../../utils/functions";

const AddEditEstructure = ({ est, setMessage }) => {
    const classes = useStyles();
    const history = useHistory();

    const [estructure, setEstructure] = useState(est);

    const dispatch = useDispatch();
    const editEstructureDispatch = (data) => dispatch(editEstructureAction(data));

    // const rateDollar = useSelector((state) => state.sunat.dollarRate);

    const handleChange = (event) => {
        const { value, name } = event.target;
        setEstructure((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitSale = async (event) => {
        event.preventDefault();

        const data = {
            ...estructure,
            est_total_sale_sol: fixDecimal(estructure.est_total_sale_sol),
            est_total_sale_dolar: fixDecimal(estructure.est_total_sale_dolar),
            est_igv_sol: fixDecimal(estructure.est_igv_sol),
            est_igv_dolar: fixDecimal(estructure.est_igv_dolar),
            est_sale_value_sol: fixDecimal(estructure.est_sale_value_sol),
            est_sale_value_dolar: fixDecimal(estructure.est_sale_value_dolar),
            est_direct_costs_total: fixDecimal(estructure.est_direct_costs_total),
            est_variable_costs_total: fixDecimal(estructure.est_variable_costs_total),
            est_impre_costs_unfo: fixDecimal(estructure.est_impre_costs_unfo),
            est_impre_costs_unfo_percent: fixDecimal(estructure.est_impre_costs_unfo_percent),
            est_impre_costs_guarant: fixDecimal(estructure.est_impre_costs_guarant),
            est_impre_costs_guarant_percent: fixDecimal(estructure.est_impre_costs_guarant_percent),
            est_impre_cost_total: fixDecimal(estructure.est_impre_cost_total),
            est_sum_total_abc: fixDecimal(estructure.est_sum_total_abc),
            est_finan_pre_service: fixDecimal(estructure.est_finan_pre_service),
            est_finan_provi_percent: fixDecimal(estructure.est_finan_provi_percent),
            est_finan_provi: fixDecimal(estructure.est_finan_provi),
            est_finan_profit_percent: fixDecimal(estructure.est_finan_profit_percent),
            est_finan_profit: fixDecimal(estructure.est_finan_profit),
            est_finan_profit_real: fixDecimal(estructure.est_finan_profit_real),
            est_tax_igv: fixDecimal(estructure.est_tax_igv),
            est_tax_export: fixDecimal(estructure.est_tax_export),
            est_tax_export_percent: fixDecimal(estructure.est_tax_export_percent),
            est_tax_total: fixDecimal(estructure.est_tax_total),
            est_real_income: fixDecimal(estructure.est_real_income),
            est_sum_total_abcd: fixDecimal(estructure.est_sum_total_abcd),
            est_dif_income_expenses: fixDecimal(estructure.est_dif_income_expenses),
            est_total_expenses: fixDecimal(estructure.est_total_expenses),
            est_breakeven: fixDecimal(estructure.est_breakeven),
        };

        try {
            await editEstructureDispatch(data);
            setMessage({ msg: "SE EDITÓ ESTRUCTURA CORRECTAMENTE", sev: "success", open: true });
        } catch (error) {
            setMessage({ msg: "HUBO UN ERROR", sev: "error", open: true });
        }

        // history.push("/estructures");
    };

    // RETURN ESTRUCTURA
    return (
        <form onSubmit={submitSale} className={classes.form}>
            <Box borderRadius={8} boxShadow={3} className={classes.formRegister}>
                <Box mb={2} pt={2}>
                    <Typography variant="h4" align="center">
                        AGREGAR ESTRUCTURA DE COSTOS
                    </Typography>
                </Box>
                <Grid container spacing={1} alignItems="center">
                    <Box mt={2} mb={6} mx={4} width="100%">
                        <Grid item lg={12}>
                            <Grid container spacing={1}>
                                <Grid item lg={12} xl={12}>
                                    <Box ml={4}>
                                        <Typography variant="h6">Datos Generales</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} xl={2}>
                                    <Box
                                        width="100%"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        height="100%"
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            style={{
                                                height: "40px",
                                                fontSize: "16px",
                                                color: estructure.est_actived ? "green" : "gray",
                                                background: estructure.est_actived
                                                    ? "#8effad"
                                                    : "#dbdbdb",
                                            }}
                                            onClick={() =>
                                                setEstructure((prevState) => ({
                                                    ...prevState,
                                                    est_actived: !estructure.est_actived,
                                                }))
                                            }
                                        >
                                            {estructure.est_actived ? "Activo" : "Inactivo"}
                                        </Button>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} xl={2}>
                                    <CustomField
                                        label="Deescripción"
                                        type="text"
                                        name="est_description"
                                        value={estructure.est_description}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg={3} xl={3}>
                                    <CustomField
                                        label="Fecha"
                                        type="date"
                                        name="est_date"
                                        value={estructure.est_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>

                                <Box width="100%" />

                                <InformationRow
                                    estructure={estructure}
                                    setEstructure={setEstructure}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={4} mx={4}>
                        <DirectCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                    <Box mb={4} mx={4}>
                        <VariableCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                    <Box mb={4} mx={4}>
                        <ImpreviseCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                    <Box mb={4} mx={4}>
                        <FinancialCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                    <Box mb={4} mx={4}>
                        <TaxCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                    <Box mb={4} mx={4}>
                        <ResultsCostRow estructure={estructure} setEstructure={setEstructure} />
                    </Box>
                </Grid>
            </Box>

            <Box>
                <Grid container justify="center" alignContent="center">
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button
                            variant="contained"
                            // color="secondary"
                            onClick={() => history.push("/estructures")}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={1} sm={1} lg={1} xl={1}>
                        <Button variant="contained" color="primary" type="submit">
                            Aceptar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default AddEditEstructure;
