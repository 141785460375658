const hideablesColumns = [
    {
        name: "FECHA E.",
        open: true,
    },
    {
        name: "FECHA V.",
        open: true,
    },
    {
        name: "TIPO C.",
        open: true,
    },
    {
        name: "CONTACTO",
        open: true,
    },
    {
        name: "DESCRIPCIÓN",
        open: true,
    },

    {
        name: "OBSERVACIÓN",
        open: true,
    },
];

const statePayments = ["Pendiente", "Total", "Excedido", "No Paga"];

const defaultDirectCost = [
    {
        dir_cost_name: "Auditor Líder",
        dir_cost_value: 0,
        dir_cost_quantity: 1,
        dir_cost_total: 0,
    },
    { dir_cost_name: "Auditor 1", dir_cost_value: 0, dir_cost_quantity: 1, dir_cost_total: 0 },
    { dir_cost_name: "Auditor 2", dir_cost_value: 0, dir_cost_quantity: 1, dir_cost_total: 0 },
    {
        dir_cost_name: "Gestor de Expediente",
        dir_cost_value: 0,
        dir_cost_quantity: 1,
        dir_cost_total: 0,
    },
    {
        dir_cost_name: "Gerente Técnico",
        dir_cost_value: 0,
        dir_cost_quantity: 1,
        dir_cost_total: 0,
    },
];

export { hideablesColumns, statePayments, defaultDirectCost };
