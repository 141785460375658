import React, { useEffect } from "react";

//redux
import { findAuthAction } from "./redux/actions/auth.action";
import { useDispatch, useSelector } from "react-redux";

//Componentes
import Login from "./components/login/Login";
import Loader from "./components/loader/Loader";
import Container from "./components/Container";

const App = () => {
    //uso del dispatch de redux para las acciones
    const dispatch = useDispatch();
    // const notAuthDispatch = (data) => dispatch(notAuthAction(data));
    const findAuthDispatch = (data) => dispatch(findAuthAction(data));

    //uso del selecter para obtener datos de auth
    const loading = useSelector((state) => state.auth.loading);
    const auth = useSelector((state) => state.auth.auth);

    //verifica si hay authenticación
    useEffect(
        () => {
            findAuthDispatch();
        },
        // eslint-disable-next-line
        []
    );

    if (auth?.success === true) {
        return <Container />;
    }

    //en caso de no estar autenticado retorna componente login
    if (auth?.success === false) return <Login />;

    //en caso de estar cargando algo se ejecutará el Loader
    if (loading) return <Loader />;

    //ejecutar el loader por transición hasta evaluación de autenticación
    return <Loader />;
};
export default App;
