import React, { Fragment } from "react";

//styles and materials
import { Grid, Button, Box, Typography, Tooltip, IconButton } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import CustomField from "../../customComponents/CustomField";

import { formatedMoney } from "../../../utils/functions";

const VariableCostRow = ({ estructure, setEstructure, typeSubmit }) => {
    // const classes = useStyles();

    const handleAddClick = () => {
        setEstructure({
            ...estructure,
            est_variable_costs: [
                ...estructure.est_variable_costs,
                {
                    var_cost_name: "",
                    var_cost_value: 0,
                    var_cost_quantity: 1,
                    var_cost_total: 0,
                },
            ],
        });
    };

    const handleRemoveClick = (index) => {
        const list = [...estructure.est_variable_costs];
        list.splice(index, 1);

        const total = list.reduce((total, value) => total + value.var_cost_total, 0);
        const abc = estructure.est_direct_costs_total + total + estructure.est_impre_cost_total;

        const resultValue =
            estructure.est_type_money === "Dolares"
                ? estructure.est_total_sale_dolar
                : estructure.est_total_sale_sol;
        const net_value = resultValue / 1.18;

        const financial_provision = ((net_value - abc) * estructure.est_finan_provi_percent) / 100;
        const financial_profit = (net_value * estructure.est_finan_profit_percent) / 100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total =
            resultValue - net_value + (resultValue * estructure.est_tax_export_percent) / 100;

        setEstructure({
            ...estructure,
            est_variable_costs: list,
            est_variable_costs_total: total,

            est_finan_pre_service: net_value - abc,
            est_finan_provi: financial_provision,
            est_finan_profit: financial_profit,
            est_finan_profit_real: financial_profit_real,

            est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * estructure.est_tax_export_percent) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total || 0.0,
        });
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...estructure.est_variable_costs];
        list[index][name] = value;

        list[index]["var_cost_total"] =
            (name === "var_cost_value" && value * list[index].var_cost_quantity) ||
            (name === "var_cost_quantity" && value * list[index].var_cost_value) ||
            list[index].var_cost_total;

        const total = list.reduce((total, value) => total + value.var_cost_total, 0);
        const abc = estructure.est_direct_costs_total + total + estructure.est_impre_cost_total;

        const resultValue =
            estructure.est_type_money === "Dolares"
                ? estructure.est_total_sale_dolar
                : estructure.est_total_sale_sol;
        const net_value = resultValue / 1.18;

        const financial_provision = ((net_value - abc) * estructure.est_finan_provi_percent) / 100;
        const financial_profit = (net_value * estructure.est_finan_profit_percent) / 100;
        const financial_profit_real = net_value - (abc + financial_provision + financial_profit);

        const tax_total =
            resultValue - net_value + (resultValue * estructure.est_tax_export_percent) / 100;

        setEstructure({
            ...estructure,
            est_variable_costs: list,
            est_variable_costs_total: total,
            est_sum_total_abc: abc,

            est_finan_pre_service: net_value - abc,
            est_finan_provi: financial_provision,
            est_finan_profit: financial_profit,
            est_finan_profit_real: financial_profit_real,

            est_tax_igv: resultValue - net_value,
            est_tax_export: (resultValue * estructure.est_tax_export_percent) / 100,
            est_tax_total: tax_total,

            est_real_income: resultValue - tax_total,
            est_sum_total_abcd: abc + financial_profit_real,
            est_dif_income_expenses: net_value - (abc + financial_profit_real),
            est_total_expenses: abc + financial_profit_real,
            est_breakeven: (abc + financial_profit_real) / tax_total,
        });
    };

    return (
        <Grid container item lg={12} spacing={1}>
            <Grid item lg={12} xl={12}>
                <Box display="flex" ml={4}>
                    <Typography variant="h6">Costos Variables Personal (B)</Typography>
                    <Box ml={1}>
                        <Button
                            variant="contained"
                            onClick={handleAddClick}
                            color="primary"
                            size="small"
                            disabled={typeSubmit === "show"}
                        >
                            +
                        </Button>
                    </Box>
                </Box>
            </Grid>

            {estructure.est_variable_costs.map((cost, i) => (
                <Fragment key={i}>
                    <Grid item xs={12} sm={6} lg={3} xl={3}>
                        <CustomField
                            label="Nombre"
                            type="text"
                            name="var_cost_name"
                            value={cost?.var_cost_name}
                            onChange={(e) => handleInputChange(e, i)}
                            disabled={typeSubmit === "show"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={3}>
                        <CustomField
                            label="Valor Unitario"
                            type="text"
                            name="var_cost_value"
                            value={cost?.var_cost_value}
                            onChange={(e) => handleInputChange(e, i)}
                            disabled={typeSubmit === "show"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} xl={3}>
                        <CustomField
                            label="Cantidad"
                            type="text"
                            name="var_cost_quantity"
                            value={cost?.var_cost_quantity}
                            onChange={(e) => handleInputChange(e, i)}
                            disabled={typeSubmit === "show"}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={2} xl={3}>
                        <CustomField
                            label="Total"
                            type="text"
                            name="var_cost_total"
                            value={formatedMoney(cost?.var_cost_total)}
                            disabled={typeSubmit === "show"}
                        />
                    </Grid>

                    <Grid container item xs={12} sm={6} lg={1} xl={3}>
                        <Tooltip title="Eliminar">
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleRemoveClick(i)}
                                disabled={typeSubmit === "show"}
                            >
                                <DeleteForeverIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Box width="100%" />
                </Fragment>
            ))}

            <Grid item lg={5} />

            <Grid container item lg={2} xl={3} justify="center" alignItems="center">
                <Typography variant="subtitle1">TOTAL:</Typography>
            </Grid>

            <Grid item xs={12} sm={6} lg={2} xl={3}>
                <CustomField
                    label="Total Costos Variable"
                    type="text"
                    name="est_variable_costs_total"
                    value={formatedMoney(estructure.est_variable_costs_total)}
                    disabled={typeSubmit === "show"}
                />
            </Grid>
        </Grid>
    );
};

export default VariableCostRow;
