import { ActionTypes } from "../types/action.types";
import { addSaleAPI, listSalesBrAPI, editSaleAPI } from "../../axios/saleAPI";
import { addPaymentAPI, editPaymentAPI } from "../../axios/paymentAPI";

//LISTADO DE VENTAS
const listingSales = () => ({
    type: ActionTypes.LISTING_SALES_BR,
    payload: true,
});

const listSales = (sales) => ({
    type: ActionTypes.LIST_SALES_BR,
    payload: sales,
});

const listSalesError = (error) => ({
    type: ActionTypes.LIST_SALES_ERROR_BR,
    payload: error,
});

export const listSalesBrAction = () => {
    return async (dispatch) => {
        dispatch(listingSales());

        try {
            const res = await listSalesBrAPI();

            res.data.sort((a, b) => {
                let fa = a.sale_num_serie.toLowerCase(),
                    fb = b.sale_num_serie.toLowerCase();

                if (fa < fb) {
                    return 1;
                }
                if (fa > fb) {
                    return -1;
                }
                return 0;
            });
            dispatch(listSales(res.data));
        } catch (error) {
            dispatch(listSalesError(error.response.data.error));
        }
    };
};

//AGREGADO DE VENTA
const addSale = () => ({
    type: ActionTypes.ADD_SALE_BR,
    payload: true,
});

const addSaleDone = (sale) => ({
    type: ActionTypes.ADD_SALE_DONE_BR,
    payload: sale,
});

const addSaleError = (error) => ({
    type: ActionTypes.ADD_SALE_ERROR_BR,
    payload: error,
});

export const addSaleBrAction = (sale) => {
    return async (dispatch) => {
        dispatch(addSale());

        try {
            const res = await addSaleAPI(sale);

            //si todo sale bien actualizar el state
            dispatch(addSaleDone(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(addSaleError(error.response.data.error));
        }
    };
};

//SELECCIÓN DE VENTA
const selectingSale = (sale) => ({
    type: ActionTypes.SELECTING_SALE_BR,
    payload: sale,
});

export const selectSaleBrAction = (sale) => {
    return async (dispatch) => {
        dispatch(selectingSale(sale));
    };
};

//EDITAR VENTA
const editingSale = () => ({
    type: ActionTypes.EDITING_SALE_BR,
    payload: true,
});

const editSale = (params) => ({
    type: ActionTypes.EDIT_SALE_DONE_BR,
    payload: params,
});

const editSaleError = (error) => ({
    type: ActionTypes.EDIT_SALE_ERROR_BR,
    payload: error,
});

export const editSaleBrAction = (sale) => {
    return async (dispatch) => {
        dispatch(editingSale());

        try {
            const res = await editSaleAPI(sale);

            //si todo sale bien actualizar el state
            dispatch(editSale(res.data));
        } catch (error) {
            //si hay error cambiamos el state
            dispatch(editSaleError(error.response.data.error));
        }
    };
};

const changeStateOpeRow = (row) => ({
    type: ActionTypes.OPENCLOSE_SALE_LIST_BR,
    payload: row,
});

export const openCloseRowAction = (row) => {
    return (dispatch) => {
        dispatch(changeStateOpeRow(row));
    };
};

const changeHeightTable = (height) => ({
    type: ActionTypes.SCROLL_TABLE_SALE_BR,
    payload: height,
});

export const tableScrollAction = (row) => {
    return (dispatch) => {
        dispatch(changeHeightTable(row));
    };
};

/////////////////////7
//////////////////7/
////////////////////7
//////////////////
//AGREGADO DE PAGO
const addPayment = () => ({
    type: ActionTypes.ADDING_PAYMENT_BR,
    payload: true,
});

//DENTRO DEL REDUCER DE SALE
const addPaymentToSaleDone = (pay) => ({
    type: ActionTypes.ADD_PAYMENT_OF_SALE_BR,
    payload: pay,
});

const addPaymentError = (error) => ({
    type: ActionTypes.ADD_PAYMENT_ERROR_BR,
    payload: error,
});

export const addPaymentBrAction = (pay) => {
    return async (dispatch) => {
        dispatch(addPayment());

        try {
            const res = await addPaymentAPI(pay);
            dispatch(addPaymentToSaleDone(res.data));
        } catch (error) {
            dispatch(addPaymentError(error));
            // console.log(error);
        }
    };
};

//SELECCIÓN DE VENTA
const selectingPayment = (pay) => ({
    type: ActionTypes.SELECTING_PAYMENT_BR,
    payload: pay,
});

export const selectPaymentAction = (pay) => {
    return async (dispatch) => {
        dispatch(selectingPayment(pay));
    };
};

//
//
//EDITAR PAGO
const editingPayment = () => ({
    type: ActionTypes.EDITING_PAYMENT_BR,
    payload: true,
});

const editPayment = (pay) => ({
    type: ActionTypes.EDIT_PAYMENT_OF_SALE_BR,
    payload: pay,
});

const editPaymentError = (error) => ({
    type: ActionTypes.EDIT_PAYMENT_ERROR_BR,
    payload: error,
});

export const editPaymentBrAction = (pay) => {
    return async (dispatch) => {
        dispatch(editingPayment());

        try {
            const res = await editPaymentAPI(pay);
            dispatch(editPayment(res.data));
        } catch (error) {
            dispatch(editPaymentError(error));
        }
    };
};
